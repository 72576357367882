import React, { useState, useCallback, useEffect } from 'react';
import _, { debounce } from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation, useApolloClient } from '@apollo/client';
import {
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
  InputGroup,
  Modal,
  FormFeedback
} from 'reactstrap';

import { GQL_PAYMENTLINK_CREATE, GQL_CREATE_PAYMENTTRANSACTION, GQL_CUSTOMER_SELECT } from '../../gqls';
import { sanitizeDOM, validator } from '../../utils';
import { addToast } from '../../actions';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import MayarAlert from '../alert/alert';
import Icon from '../icon';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString } from '../rich-text-editor';
import { EditorState } from 'draft-js';

const gparam = {
  limit: 6,
  sortDirection: 'ASC',
  sortField: 'name',
  search: {
    mobile: [{ operator: 'not', value: '' }, { operator: 'notEmpty' }]
  },
  searchAny: {}
};

const ModalBodyPaymentRequest = ({ isOpen, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { register, handleSubmit, errors, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  const stateEdit = EditorState.createEmpty();
  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  const history = useHistory();
  const toCustomerPage = useCallback(() => {
    onClose();
    history.push('/customers');
  }, [history]);

  const [insertPaymentLink, { loading }] = useMutation(GQL_PAYMENTLINK_CREATE);
  const [createPaymentTransaction, { loading: loadingPLT }] = useMutation(GQL_CREATE_PAYMENTTRANSACTION);

  const mapOptionsToValues = options =>
    options.map(option => ({
      value: option.id,
      label: option.name + ' (' + option.email + ')'
    }));

  const _loadSuggestions = (keyword, callback) => {
    if (!keyword || keyword.length < 3) {
      return callback([]);
    }

    gparam.searchAny = {
      email: [{ operator: 'like', value: keyword + '%' }],
      name: [{ operator: 'like', value: '%' + keyword + '%' }]
    };
    client
      .query({
        query: GQL_CUSTOMER_SELECT,
        variables: gparam,
        context: { queryDeduplication: false }
      })
      .then(resp => callback(mapOptionsToValues(resp.data.getCustomerFilteredList)));
  };
  const loadSuggestions = debounce(_loadSuggestions, 500);

  const sendMail = async (paymentLinkId, customerEmail) => {
    try {
      await axios.post(`${process.env.REACT_APP_PROXY}/mail-invoice`, { id: paymentLinkId });
      dispatch(
        addToast({
          title: 'Email terkirim',
          content: 'Kami telah mengirimkan email penagihan ini kepada ' + customerEmail,
          duration: 3000
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: 'Email gagal dikirim',
          duration: 3000,
          color: 'danger'
        })
      );
    }
  };

  // const toCustomerPage = () => {
  //   <Link to="/customers">Halaman Pelanggan</Link>
  // }

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }
    try {
      const input = {
        // description: values.description,
        description: sanitizeDOM(description),
        amount: parseFloat(values.amount),
        link: Math.random().toString(36).substr(2, 12),
        type: 'payment_request',
        status: 'unpaid',
        notes: values.notes,
        name: 'Penagihan'
      };

      if (customerId) input.customerId = values.customerId.value;

      const resPL = await insertPaymentLink({
        variables: {
          input
        }
      });

      const paymentLink = _.get(resPL, 'data.insertPaymentLink', {});

      if (customerId) {
        // if payment payer is anonymous
        const { customer } = paymentLink || {};

        const resPLT = await createPaymentTransaction({
          variables: {
            input: {
              email: customer.email,
              mobile: customer.mobile,
              name: customer.name,
              paymentLinkId: paymentLink?.id
            }
          }
        });

        const paymentLinkTransaction = _.get(resPLT, 'data.createPaymentTransaction', {});

        if (!paymentLinkTransaction?.id) throw new Error('Payment Link Transaction not created');

        if (values.sendNotif) sendMail(paymentLink?.id, values?.customerId?.label || paymentLink?.customerId);
      }

      onSuccess(paymentLink?.id);
      //segment tracking
      window.analytics.track('Create Payment Request', values);
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Payment Request - ERROR', values);
    }
  };

  return (
    <Modal isOpen={isOpen} fade size="lg">
      <div className="modal-header">
        <h5 className="modal-title h2">Buat Penagihan</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Request pembayaran dari siapapun dengan berbagai metode pembayaran. Masukkan detail pembayar kamu dibawah
            dan kami akan menagihkan untuk kamu, dan kami akan memberitahukan saat sudah dibayar.
          </small>
        </p>
        <hr />
        <MayarAlert isError={submitError}></MayarAlert>
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-request">
          <FormGroup>
            <Label for="pelanggan2">Pelanggan (Opsional)</Label>
            <Controller
              render={({ onChange }) => (
                <AsyncSelect
                  className={Boolean(errors.customerId) ? 'is-invalid' : ''}
                  loadOptions={loadSuggestions}
                  defaultOptions
                  escapeClearsValue={true}
                  openMenuOnClick={false}
                  isSearchable
                  isClearable={true}
                  placeholder="Cari pelanggan...(ketik minimal 3 huruf awal)"
                  cacheOptions={false}
                  styles={Boolean(errors.customerId) ? CustomStylesInvalid : CustomStyles}
                  onChange={e => {
                    if (e?.value) setCustomerId(e.value);
                    else setCustomerId(null);
                    onChange(e);
                  }}
                />
              )}
              name="customerId"
              control={control}
              defaultValue={''}
              // rules={{ required: validator.required }}
            />
            <FormFeedback>{errors.customerId?.message}</FormFeedback>
            <small>
              Kamu perlu membuat data pelanggan dahulu di{' '}
              <span
                className="cursor-pointer"
                onClick={toCustomerPage}
                onKeyDown={toCustomerPage}
                aria-hidden="true"
                style={{ color: '#007bff' }}
              >
                Halaman Pelanggan
              </span>
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="amount">Jumlah*</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Rp</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                name="amount"
                id="amount"
                invalid={Boolean(errors.amount)}
                innerRef={register({
                  required: validator.required,
                  min: validator.min(1000),
                  max: validator.max(10000000)
                })}
              />
              <FormFeedback>{errors?.amount?.message}</FormFeedback>
            </InputGroup>
            <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
          </FormGroup>
          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>
          <hr />
          <FormGroup>
            <Label for="notes">Catatan</Label>
            <Input type="textarea" name="notes" id="notes" invalid={errors.notes} innerRef={register()} />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional).{' '}
            </small>
          </FormGroup>
          {customerId && (
            <FormGroup>
              <CustomInput
                id="formSwitch1"
                label="Kirim Email Pemberitahuan"
                name="sendNotif"
                type="switch"
                color="primary"
                innerRef={register()}
              />
            </FormGroup>
          )}
          <SubmitButton
            size="lg"
            block
            color="brand"
            className="text-center"
            type="submit"
            loading
            isLoading={loading || loadingPLT}
            text="Buat Penagihan"
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

ModalBodyPaymentRequest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ModalBodyPaymentRequest;
