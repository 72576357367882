import { gql } from '@apollo/client';

export const GET_PAYMENT_LINK_TRANSACTION_LIST_EVENT = gql`
  query getPaymentLinkTransactionFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkTransactionSortField
    $searchAny: PaymentLinkTransactionSearchInput
    $search: PaymentLinkTransactionSearchInput
  ) {
    getPaymentLinkTransactionFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      status
      customerId
      givenCustomerId
      updatedAt
      createdAt
      urlParamTrackingData
      orderVariant
      qty
      paymentMethod
      customeField {
        data
      }
      customer {
        name
        email
        mobile
      }
      eventHistory {
        ticket {
          name
        }
      }
    }
  }
`;

export const GET_PAYMENT_LINK_TRANSACTION_LIST = gql`
  query getPaymentLinkTransactionFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkTransactionSortField
    $searchAny: PaymentLinkTransactionSearchInput
    $search: PaymentLinkTransactionSearchInput
  ) {
    getPaymentLinkTransactionFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      amount
      status
      customerId
      updatedAt
      createdAt
      urlParamTrackingData
      orderVariant
      qty
      message
      paymentMethod
      customeField {
        data
      }
      customer {
        name
        email
        mobile
      }
      givenCustomer {
        name
        email
        mobile
      }
    }
  }
`;

export const GET_PAYMENT_LINK_TRANSACTION_LIST_IDS = gql`
  query getPaymentLinkTransactionFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkTransactionSortField
    $searchAny: PaymentLinkTransactionSearchInput
    $search: PaymentLinkTransactionSearchInput
  ) {
    getPaymentLinkTransactionFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      amount
    }
  }
`;

export const GET_PAYMENT_LINK_TRANSACTION_LIST_COUPONS = gql`
  query getPaymentLinkTransactionFilteredList(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: PaymentLinkTransactionSortField
    $searchAny: PaymentLinkTransactionSearchInput
    $search: PaymentLinkTransactionSearchInput
  ) {
    getPaymentLinkTransactionFilteredList(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      searchAny: $searchAny
      search: $search
    ) {
      id
      amount
      customerId
      urlParamTrackingData
      message
      status
      subscriptionCustomerId
      subscriptionId
      updatedAt
      createdAt
      userId
      paymentMethod
      customeField {
        data
      }
      couponUsage {
        coupons {
          code
        }
      }
      paymentLink {
        name
        status
        type
      }
      customer {
        name
        email
        mobile
      }
    }
  }
`;

export const EDIT_PLT = gql`
  mutation editPaymentLinkTransaction($input: PaymentLinkTransactionInput) {
    editPaymentLinkTransaction(input: $input) {
      id
      isContentBlocked
    }
  }
`;
