import React, { useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { Modal, ModalBody, Form, FormGroup, Label, Input, Button, Alert, FormFeedback } from 'reactstrap';

import { GQL_INSERT_BROADCAST, GQL_EDIT_BROADCAST } from '../../../gqls';
import { validator, broadcastOptions, slug } from '../../../utils';
import { updateBroadcast } from '../../../actions/broadcast';
import { CustomStyles, CustomStylesInvalid } from '../../custom-styles/react-select-styles';
import Icon from '../../icon';
import SubmitButton from '../../submit-button';
import EmailForm from './components/email-form';

const BroadcastForm = ({ type, data, formState, isUpdate, isDuplicate }) => {
  switch (type) {
    case 'email':
      return <EmailForm data={data} formState={formState} isUpdate={isUpdate} isDuplicate={isDuplicate} />;
    default:
      return null;
  }
};

const ModalBroadcast = ({ data, isOpen, isUpdate, isDuplicate, onSuccess, onClose }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [broadcastChannel, setBroadcastChannel] = useState('email');

  const [insertBroadcast, { loading: insertLoading }] = useMutation(GQL_INSERT_BROADCAST);
  const [editBroadcast, { loading: editLoading }] = useMutation(GQL_EDIT_BROADCAST);

  const formState = { register, errors, setValue };
  const isReopen = isUpdate && data?.status === 'archived';
  const isLoading = insertLoading || editLoading;

  const onSubmit = async values => {
    const variables = {
      ...values,
      campaign: slug(!isUpdate ? values.name : values.campaign),
      ...(isUpdate && { id: data?.id }),
      ...((isDuplicate || !isUpdate) && { isEmailSent: false }),
      ...((isDuplicate || !isUpdate || isReopen) && { status: 'drafted' }),
      ...(isDuplicate && { message: data.message, unlayerDesign: data.unlayerDesign })
    };

    const onBroadcastCreated = id => {
      onClose();
      onSuccess(id);
      window.analytics.track('Create Broadcast / Blast', values);
    };

    try {
      if (isDuplicate) {
        const res = await insertBroadcast({ variables: { input: variables } });
        onBroadcastCreated(res.data.insertBroadcastBySlug.id);
      } else if (isUpdate) {
        await editBroadcast({ variables: { input: variables } });
        dispatch(updateBroadcast(Math.random()));
      } else {
        const res = await insertBroadcast({ variables: { input: variables } });
        onBroadcastCreated(res.data.insertBroadcastBySlug.id);
      }
    } catch (err) {
      setSubmitError(true);
      window.analytics.track('Create Broadcast / Blast - ERROR', values);
    }
  };

  const title = () => {
    if (isDuplicate) {
      return 'Duplikat';
    } else if (isReopen) {
      return 'Buka';
    } else if (isUpdate) {
      return 'Edit';
    }

    return 'Buat';
  };

  return (
    <Modal isOpen={isOpen} fade size="lg">
      <div className="modal-header">
        <h5 className="modal-title h2">{title()} Broadcast</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Broadcast / Blast, mengirim pesan broadcast / blast ke pelanggan anda semakin mudah dengan Mayar.
          </small>
        </p>
        <hr />
        {submitError && (
          <div className="mb-10">
            <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>
          </div>
        )}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Nama Broadcast*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={!!errors.name}
              defaultValue={data?.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="channel">Saluran Broadcast/Blast*</Label>
            <Controller
              name="channel"
              control={control}
              defaultValue={broadcastChannel}
              render={({ onChange, name, value }) => (
                <Select
                  name={name}
                  id={name}
                  inputRef={register({ required: validator.required })}
                  options={broadcastOptions}
                  placeholder="Pilih Saluran..."
                  defaultValue={broadcastOptions.find(({ value: channelValue }) => channelValue === value)}
                  styles={!!errors.channel ? CustomStylesInvalid : CustomStyles}
                  className={`text-left ${!!errors.channel ? 'is-invalid' : ''}`}
                  onChange={e => {
                    onChange(e.value);
                    setBroadcastChannel(e.value);
                  }}
                />
              )}
            />
            <FormFeedback>{errors.channel?.message}</FormFeedback>
          </FormGroup>
          <BroadcastForm
            type={broadcastChannel}
            data={data}
            formState={formState}
            isUpdate={isUpdate}
            isDuplicate={isDuplicate}
          />
          <Alert color="light" className="text-center mb-10">
            <strong>Anda dapat mendesain dan melayout format pesan broadcast setelah proses ini.</strong>
          </Alert>
          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center mt-10"
            loading
            isLoading={isLoading}
            text={`${title()} Broadcast`}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

ModalBroadcast.defaultProps = {
  isOpen: false,
  isUpdate: false,
  isDuplicate: false
};

export default ModalBroadcast;
