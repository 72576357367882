import { productOptionsHeader, getPaymentLinkDetailPath } from '.';

export const generateSKU = product => {
  return `${product.category.substring(0, 3)}/${product.name.substring(0, 3)}${
    product.vendor ? `/${product.vendor.substring(0, 3)}` : ''
  }`.toUpperCase();
};

export const generateVariantSKU = (product, variant, index) => {
  let indexProduct = index;

  switch (index.toString().length) {
    case 1:
      indexProduct = `00${index}`;
      break;
    case 2:
      indexProduct = `0${index}`;
      break;
    default:
      indexProduct = `${index}`;
      break;
  }

  return `${product.category.substring(0, 3)}/${product.name.substring(0, 3)}/${variant.option
    .split('/')
    .map(item => item.substring(0, 3))
    .join('/')}/${indexProduct}`.toUpperCase();
};

export const checkSKU = (productValue, productOrigin) => {
  if (
    (productOrigin.sku === generateSKU(productOrigin) && productOrigin.category !== productValue.category) ||
    productOrigin.name !== productValue.name ||
    productOrigin.vendor !== productValue.vendor
  )
    return generateSKU(productValue);

  if (productValue.sku) return productValue.sku;
  return generateSKU(productValue);
};

export const generateRandomString = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const createReferralCode = length => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charArray = characters.split('');
  let result = '';
  for (let i = 0; i < length; i++) {
    result += charArray[Math.floor(Math.random() * characters.length)];
  }
  return result;
};

export const createProductList = (paymentLinkList = [], productType = 'payment_link', addOnObjectKeys = []) => {
  return paymentLinkList.map(item => {
    const product = {
      ...item.paymentLink,
      name: item.paymentLink.name,
      id: item.paymentLink.id,
      type: item.paymentLink.type,
      productType: item.productType || productType,
      variantId: item.variantId
    };

    product.link = getPaymentLinkDetailPath(product.type, product.subType);

    if (product.productType !== 'payment_link') {
      product.id = item.subscriptionId;
      product.name = item.subscription?.name;
      product.type = 'subscription';
      product.link = '/payment-plan-detail/';
    }

    for (const key of addOnObjectKeys) {
      if (item.hasOwnProperty(key)) {
        product[key] = item[key];
      }
    }

    return product;
  });
};

export const createProductListOptions = (paymentLinkList = [], listHeader = productOptionsHeader, isAffiliateProductList) => {
  const productList = [...listHeader];

  paymentLinkList.forEach(item => {
    const product = {
      value: item.id,
      label: item.name,
      ...(isAffiliateProductList && {
      label: item.name + ` - ${item.affiliateCommission ? `${item.affiliateCommission}%` : " [Komisi belum ditentukan!]"}`,
      isDisabled: !item.affiliateCommission
      }),
      type: 'payment_link',
      paymentLinkType: item?.type || null,
      eventId: item?.event?.id || null,
      variant: item?.variant
    };

    const headerIndex = productList.findIndex(({ value }) => value === item.type);
    const startIndex = headerIndex >= 0 ? headerIndex + 1 : productList.length - 1;

    productList.splice(startIndex, 0, product);
  });

  return productList;
};

export const getDisbursementFee = () => {
  const fee = parseFloat(process.env.REACT_APP_DISBURSEMENT_FEE_XENDIT);
  const vat = (parseFloat(process.env.REACT_APP_DISBURSEMENT_VAT_PERCENTAGE_XENDIT) / 100) * fee;

  return fee + vat;
};
