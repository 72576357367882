import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { validator, slug, sanitizeDOM, membershipTypes } from '../../utils';
import {
  CREATE_MEMBERSHIP_BY_SLUG,
  UPDATE_MEMBERSHIP_BY_SLUG,
  GQL_GET_USER_CONTENTS,
  GQL_INSERT_USER_CONTENT_FILE,
  GQL_UPDATE_USER_CONTENT_FILE,
  GQL_GET_CONTENT_FILE
} from '../../gqls';
import { updatePaymentLinks } from '../../actions/payment-links';
import MayarAlert from '../alert/alert';
import LoadingAnimation from '../loading-animation';
import Icon from '../icon';
import SubmitButton from '../submit-button';
import RichEditor, { getContentString, getEditorContent, getPlainText } from '../rich-text-editor';
import DatePicker from '../date-time-picker';
import { CustomStyles } from '../custom-styles/react-select-styles';
import Dropzone from '../dropzone';
import MultipleImageInput from '../multiple-image-input';

const ModalBodyMembership = ({ onSuccess, isOpen, onClose, data = {} }) => {
  const isUpdate = Boolean(data && JSON.stringify(data) !== '{}');

  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();

  const [submitError, setSubmitError] = useState(false);
  const [showMembers, setShowMembers] = useState(Boolean(data.membershipInfo?.showMembers));
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
  const [membershipType, setMembershipType] = useState(data.membershipInfo?.type || 'MEMBERSHIP');

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [enableAffiliate, setEnableAffiliate] = useState(Boolean(data?.affiliateCommission));


  // Upload file
  const [isDownloadable, setIsDownloadable] = useState(Boolean(data.isDownloadable));
  const [sourceFile, setSourceFile] = useState(data.isDownloadable ? 'existing' : '');
  const [fileId, setFileId] = useState(data.content?.[0]?.file?.id);
  const [notAllowed, setNotAllowed] = useState(false);
  const [fileOptions, setfileOptions] = useState([]);

  const [insertPaymentLinkBySlug, { loading: insertLoading, data: newMembership }] = useMutation(
    CREATE_MEMBERSHIP_BY_SLUG
  );
  const [editPaymentLinkBySlug, { loading: submitUpdateLoading, data: upl }] = useMutation(UPDATE_MEMBERSHIP_BY_SLUG);

  const boolean = [
    {
      id: 1,
      label: 'YA',
      value: true
    },
    {
      id: 2,
      label: 'TIDAK',
      value: false
    }
  ];

  // Upload file
  const [userUserContent, { loading: insertUserContentLoading, data: userContent }] = useMutation(
    GQL_INSERT_USER_CONTENT_FILE
  );

  const [doUpdateUserContent, { loading: updateUserContentLoading, data: updateUserContent }] = useMutation(
    GQL_UPDATE_USER_CONTENT_FILE
  );

  const { data: getUserContent } = useQuery(GQL_GET_CONTENT_FILE, {
    variables: {
      search: {
        key: [{ operator: 'eq', value: data?.id || 'untitle' }]
      }
    },
    fetchPolicy: 'network-only'
  });

  const { loading: loadingFiles, data: dataFiles } = useQuery(GQL_GET_USER_CONTENTS, {
    variables: {
      limit: 100,
      offset: 0
    },
    context: { v2: true }
  });

  if (dataFiles?.getUserContents?.length && !fileOptions.length) {
    const options = dataFiles.getUserContents.map(i => {
      return { value: i.id, label: i.filename };
    });
    setfileOptions(options);
  }
  // End upload file

  const onSubmit = async values => {
    if (isDescriptionEmpty || (isDownloadable && !fileId)) return;

    values.description = sanitizeDOM(getContentString(values.description));
    delete values.fileSource;

    if (!isUpdate && isDownloadable && !fileId) return;

    
    if (values.affiliateCommission) values.affiliateCommission = parseInt(values.affiliateCommission);
    else values.affiliateCommission = null;
    
    const inputVariables = {
      ...values,
      ...(multipleImageId && { multipleImageId }),
      ...(isRemoveAllProductImage && { multipleImageId: null }),
      link: slug(values.name),
      status: 'active',
      type: 'membership',
      membershipInfo: {
        showMembers: Boolean(showMembers),
        type: membershipType
      }
    };

    try {
      if (isUpdate) {
        inputVariables.id = data.id;
        inputVariables.membershipInfo.id = data.membershipInfo?.id;

        const editPaymentLink = await editPaymentLinkBySlug({
          variables: { input: inputVariables }
        });

        if (fileId && isDownloadable) {
          const contentId =
            getUserContent.getMultipleFileFilteredList?.[0]?.id || editPaymentLink.data.editPaymentLinkBySlug?.id;

          if (getUserContent.getMultipleFileFilteredList.length > 0) {
            await doUpdateUserContent({
              variables: {
                input: [
                  {
                    id: contentId,
                    value: fileId
                  }
                ]
              }
            });
          } else {
            await userUserContent({
              variables: {
                input: [
                  {
                    key: contentId,
                    value: fileId
                  }
                ]
              }
            });
          }
        }

        if (editPaymentLink.data.editPaymentLinkBySlug?.id) {
          dispatch(updatePaymentLinks(Math.random()));
        }
      } else {
        inputVariables.link = slug(values.name);

        const paymentLink = await insertPaymentLinkBySlug({
          variables: { input: inputVariables }
        });

        if (paymentLink) {
          const paymentLinkId = paymentLink.data.insertPaymentLinkBySlug.id;
          await userUserContent({
            variables: {
              input: [
                {
                  key: paymentLinkId,
                  value: fileId
                }
              ]
            }
          });
        }

        window.analytics.track('Create Membership Plan', values);
      }
    } catch (error) {
      setSubmitError(true);

      window.analytics.track('Create Membership Plan - ERROR', values);
    }
  };

  if (newMembership && newMembership.insertPaymentLinkBySlug) {
    onSuccess(newMembership.insertPaymentLinkBySlug.id);
  }

  if (upl) {
    onClose();
  }

  if (upl && (userContent || updateUserContent)) onClose();

  const handleShowMembers = item => {
    setShowMembers(item.value);
  };

  return (
    <Modal isOpen={isOpen} fade size="lg">
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Membership</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>

      <ModalBody>
        <p>
          <small>Terima pembayaran berlangganan dari pelanggan semudah membagikan link</small>
        </p>
        <hr />
        <MayarAlert isError={submitError} />
        <Form onSubmit={handleSubmit(onSubmit)} id="create-Membership-plan">
          {!isUpdate && (
            <FormGroup>
              <Label for="membershipType">Tipe Produk</Label>
              <Input
                id="membershipType"
                name="membershipType"
                type="select"
                defaultValue={membershipType}
                onChange={e => setMembershipType(e.target.value)}
              >
                {Object.keys(membershipTypes).map(item => (
                  <option key={item} value={item}>
                    {membershipTypes[item]}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="name">Nama Paket*</Label>
            <Input
              defaultValue={data.name}
              type="text"
              name="name"
              id="name"
              invalid={Boolean(errors.name)}
              innerRef={register({ required: validator.required })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <Controller
              name="description"
              control={control}
              defaultValue={data.description && getEditorContent(data.description)}
              rules={{
                required: { value: true, message: 'Wajib diisi' }
              }}
              render={({ onChange, value }) => (
                <RichEditor
                  onEditorStateChange={e => {
                    setIsDescriptionEmpty(!getPlainText(e));
                    onChange(e);
                  }}
                  editorState={value}
                  invalidState={Boolean(errors.description || isDescriptionEmpty)}
                />
              )}
            />
            <FormFeedback className={(isDescriptionEmpty || errors.description) && 'd-block'}>Wajib diisi</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={data.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>

          <MultipleImageInput
            productName={data.name}
            setMultipleImageId={setMultipleImageId}
            setIsRemoveAllProductImage={setIsRemoveAllProductImage}
            multipleImage={data.multipleImage}
            onLoading={setDisableSubmitButton}
          />

          <FormGroup>
            <Label for="showMembers">Tampilkan anggota</Label>
            <Select
              defaultValue={boolean.find(item => item.value === showMembers)}
              name="showMembers"
              invalid={Boolean(errors.showMembers)}
              id="showMembers"
              options={boolean}
              placeholder="Pilih"
              onChange={handleShowMembers}
            ></Select>
            <FormFeedback>{errors.showMembers?.message}</FormFeedback>
          </FormGroup>

          {membershipType === 'SAAS' && (
            <>
              <FormGroup>
                <CustomInput
                  id="isDownloadable"
                  label="Ada file yang didownload setelah Beli?"
                  name="isDownloadable"
                  type="switch"
                  color="primary"
                  defaultChecked={data?.isDownloadable}
                  onChange={e => setIsDownloadable(e.target.checked)}
                  innerRef={register()}
                />
                <small>
                  Jika ada, anda dapat mengupload filenya disini, atau anda bisa menyertakan url file untuk downloadnya
                </small>
              </FormGroup>

              {isDownloadable && (
                <>
                  <FormGroup>
                    <Label for="fileSource">Sumber File*</Label>
                    <Input
                      type="select"
                      id="fileSource"
                      name="fileSource"
                      onChange={e => {
                        setSourceFile(e.target.value);
                      }}
                      invalid={Boolean(errors.fileSource)}
                      innerRef={register({ required: validator.required })}
                      value={sourceFile}
                    >
                      <option value="">-- Pilih Sumber File --</option>
                      {fileOptions.length && <option value="existing">File Lama</option>}
                      <option value="new">Upload Baru</option>
                    </Input>
                    <FormFeedback>{errors.fileSource?.message}</FormFeedback>
                  </FormGroup>

                  {sourceFile === 'existing' && (
                    <FormGroup>
                      <Label for="downloadFileId">Pilih File Lama*</Label>
                      {loadingFiles ? (
                        <span>
                          <LoadingAnimation twodashline />
                        </span>
                      ) : (
                        <Select
                          id="downloadFileId"
                          name="downloadFileId"
                          options={fileOptions}
                          styles={CustomStyles}
                          disabled={loadingFiles}
                          isLoading={loadingFiles}
                          defaultValue={
                            data && data.content
                              ? fileOptions.find(obj => obj.value === data?.content[0]?.file?.id)
                              : null
                          }
                          onChange={e => setFileId(e.value)}
                        />
                      )}
                      {!fileId && <FormFeedback className="error-block">File harus diisi</FormFeedback>}
                    </FormGroup>
                  )}

                  {sourceFile === 'new' && (
                    <FormGroup>
                      <Label for="images">File / Konten*</Label>
                      <Dropzone setFileId={setFileId} maxFiles={1} multiple={false} setNotAllowed={setNotAllowed} />
                      {!fileId && <FormFeedback className="error-block">File harus diisi</FormFeedback>}
                      {notAllowed && (
                        <FormFeedback className="error-block">
                          Nama file tidak boleh menggunakan special character!
                        </FormFeedback>
                      )}
                      <small>Ukuran file maksimal 1GB.</small>
                    </FormGroup>
                  )}
                </>
              )}
            </>
          )}

<FormGroup>
            <CustomInput
              id="affiliateSwitch"
              label="Produk bisa diaffiliate"
              defaultChecked={enableAffiliate}
              type="switch"
              color="primary"
              onChange={e => setEnableAffiliate(e.target.checked)}
            />
          </FormGroup>

          {enableAffiliate && (
            <FormGroup>
              <Label for="affiliateCommission">Komisi Affiliate</Label>
              <InputGroup>
                <Input
                  type="number"
                  name="affiliateCommission"
                  id="affiliateCommission"
                  defaultValue={data?.affiliateCommission}
                  invalid={errors.affiliateCommission}
                  innerRef={register({
                    min: validator.min(1),
                    max: validator.max(70)
                  })}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>

                <FormFeedback>{errors.affiliateCommission?.message}</FormFeedback>
                <small>
                  Komisi secara otomatis dipotong dari persentase total harga penjualan (setelah dipotong kode diskon
                  jika ada, biaya channel dan biaya Mayar). Komisi hanya untuk affiliator yang terdaftar pada produk ini.
                </small>
              </InputGroup>
            </FormGroup>
          )}

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            loading
            isLoading={insertLoading || submitUpdateLoading || insertUserContentLoading || updateUserContentLoading}
            isDisabled={disableSubmitButton}
            text={`${isUpdate ? 'Edit' : 'Buat'} Membership`}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

ModalBodyMembership.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalBodyMembership;
