import { gql } from '@apollo/client';

export const GET_PAGINATED_EVENT_HISTORY = gql`
  query getPaginatedEventHistoryIntegration($integrationId: ID!, $pageState: String) {
    getPaginatedEventHistoryIntegration(integrationId: $integrationId, pageState: $pageState) {
      success
      data {
        records {
          id
          status
          createdAt
          payload
        }
        nextPageState
        hasMorePages
      }
      error {
        message
        code
      }
    }
  }
`;

export const GET_INTEGRATION_TOKEN_LIST = gql`
  query getIntegrationTokenLists($id: ID, $paymentLinkId: ID) {
    getIntegrationTokenLists(id: $id, paymentLinkId: $paymentLinkId) {
      id
      name
      tokenType
      integrationId
      status 
    }
  }
`;

export const UPDATE_INTEGRATION_TOKEN = gql`
  mutation updateIntegrationToken($input: InputIntegrationToken!) {
    updateIntegrationToken(input: $input) {
      status
      message
    }
  }
`;
