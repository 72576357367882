import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroupText,
  CustomInput,
  InputGroup,
  Modal,
  FormFeedback,
  InputGroupAddon,
  Alert
} from 'reactstrap';
import Dropzone from '../../dropzone';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { GQL_GET_OTP, NEW_CREATE_DISBURSEMENT } from '../../../gqls';
import Icon from '../../icon';
import { Link } from 'react-router-dom';
import { CustomStyles, CustomStylesInvalid } from '../../custom-styles/react-select-styles';
import DatePicker from '../../date-time-picker';
import MayarAlert from '../../alert/alert';
import SubmitButton from '../../submit-button';
import {
  currencyOptions,
  bankAccountTypeOptions,
  bankCountryOptions,
  validator,
  bankCodes,
  paymentCategoryOptions,
  formatRp,
  getDisbursementFee
} from '../../../utils';
import OTPConfirmation from '../../otp-confirmation';

const recreateDisbursementPayload = (values, currentUser, invoiceExpiredAt) => {
  return {
    accountName: values.accountName,
    accountNumber: values.accountNumber,
    accountType: values.accountType?.value,
    amount: parseFloat(values.amount),
    bank: values.bank.value,
    invoiceCategory: values.invoiceCategory?.value,
    currency: values.currency?.value,
    email: values.email,
    invoiceNumber: values.invoiceNumber,
    notes: values.notes,
    bankCountry: values.bankCountry?.value,
    tax: parseInt(values.tax),
    isEmailSentAfterSuccess: values.isEmailSentAfterSuccess,
    type: 'INVOICE',
    status: 'ON_PROCESS',
    invoiceExpiredAt: invoiceExpiredAt || null,
    transferDescription: `Payment for invoice ${values.invoiceNumber ? `${values.invoiceNumber} ` : ''}from ${
      currentUser.account.name
    }`,
    fileId: values.fileId
  };
};

const ModalBillPayment = ({ onClose, isOpen, onSuccess }) => {
  const currentUser = useSelector(state => state.currentUser);
  const { register, handleSubmit, errors, control, formState, getValues } = useForm();
  const [notAllowed, setNotAllowed] = useState(false);
  const [invoiceExpiredAt, setInvoiceExpiredAt] = useState();
  const [paymentDetail, setPaymentDetail] = useState({});
  const [submitError, setSubmitError] = useState({ status: false, message: null });
  const [isAmountExceedLimit, setIsAmountExceedLimit] = useState({ status: false, message: '' });
  const bankOptions = bankCodes.map(({ Bank, BankCode }) => ({ value: BankCode, label: Bank }));
  // const disbursementFee = parseFloat(`${process.env.REACT_APP_MAYAR_DISBURSEMENT_FEE}`);
  const [disbursementFee] = useState(() => getDisbursementFee());
  const [bankAccount, setBankAccount] = useState(false);
  const [currency, setCurrency] = useState({ label: 'IDR - Indonesian Rupiah', value: 'IDR' });
  const [accountType, setAccountType] = useState(false);
  const [bankCountry, setBankCountry] = useState({ label: 'Indonesia', value: 'indonesia' });
  const [invoiceCategory, setInvoiceCategory] = useState(false);
  const [isEmailSentAfterSuccess, setIsEmailSentAfterSuccess] = useState(false);
  const [validation, setValidation] = useState('');
  const [loadingFetchBalance, setLoadingFetchBalance] = useState(false);
  const [dataBalance, setDataBalance] = useState({});
  const { balanceActive, balancePending, balance } = dataBalance;
  const [maxAmount, setMaxAmount] = useState(balanceActive);
  const [isErr, setIsErr] = useState(false);

  /* OTP STATE */
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showOtp, setShowOtp] = useState(currentUser?.kyc?.status === 'verify' && isOtpSent ? true : false);
  const [showForm, setShowForm] = useState(currentUser?.kyc?.status === 'verify' && !isOtpSent ? true : false);

  /* Check balance */
  const fetchBalance = useCallback(() => {
    setLoadingFetchBalance(true);
    axios
      .get(`${process.env.REACT_APP_PROXY}/xendit-balance`)
      .then(res => {
        setLoadingFetchBalance(false);
        setDataBalance(res.data);
      })
      .catch(() => {
        setIsErr(true);
        setLoadingFetchBalance(false);
      });
  }, []);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  const setBalance = () => {
    if (balanceActive) {
      setMaxAmount(balanceActive - disbursementFee);
      // if (balanceActive >= disbursementFee) {
      // setMaxDisbursement(balanceActive - disbursementFee);
      // }
    }
  };

  useEffect(() => {
    setBalance();
  }, [balanceActive, balancePending, balance]);

  useEffect(() => {
    if (currentUser?.kyc?.status === 'verify' && isOtpSent) setShowOtp(true);
    else setShowOtp(false);

    if (currentUser?.kyc?.status === 'verify' && !isOtpSent) setShowForm(true);
    else setShowForm(false);
  }, [showOtp, showForm, currentUser, isOtpSent]);

  const [createDisbursement, { loading: loadingDisb }] = useMutation(NEW_CREATE_DISBURSEMENT);
  const [getOtp, { error: errorOtp, loading: loadingOtp }] = useLazyQuery(GQL_GET_OTP, {
    fetchPolicy: 'network-only'
  });

  const { isValid, isSubmitting } = formState;
  useEffect(() => {
    const { amount } = getValues() || {};

    setIsAmountExceedLimit({
      status: Boolean(parseFloat(amount) > 500000000) && !isValid,
      message:
        parseFloat(amount) > 500000000 && !isValid
          ? 'Hubungi customer service untuk menaikkan limit per-transfer anda.'
          : ''
    });
  }, [isSubmitting]);

  const initFocus = document.querySelector(`input[id=input0]`);
  useEffect(() => {
    if (initFocus) {
      initFocus.focus();
    }
  }, [initFocus]);

  const handleRequestOtp = (values = null) => {
    const payload = values ? recreateDisbursementPayload(values, currentUser, invoiceExpiredAt) : paymentDetail;

    getOtp({
      variables: {
        input: {
          bankAccountName: payload.accountName,
          bankAccountNumber: payload.accountNumber,
          bankAccount: payload.bank,
          transferAmount: payload.amount,
          otpType: 'INVOICE'
        }
      }
    });
    setIsOtpSent(true);
  };

  const onSubmit = values => {
    if (maxAmount <= 0) {
      setValidation('Jumlah saldo aktif tidak mencukupi');
      return;
    }

    if (parseFloat(values.amount) >= 1000) {
      if (parseFloat(values.amount) <= maxAmount) {
        setValidation('');
      } else {
        setValidation(
          'Jumlah maksimal saldo aktif yang dapat digunakan adalah ' + formatRp(maxAmount > 0 ? maxAmount : 0)
        );
        setTimeout(() => {
          setValidation('');
        }, 5000);
        return;
      }
    }

    const payload = recreateDisbursementPayload(values, currentUser, invoiceExpiredAt);
    setPaymentDetail(payload);
    handleRequestOtp(payload);
  };

  const createBillPayment = async otp => {
    let result = { status: 'SUCCESS' };

    try {
      const response = await createDisbursement({
        variables: {
          input: {
            otp,
            type: 'INVOICE',
            amount: paymentDetail.amount,
            disbursement: paymentDetail
          }
        }
      });

      result = response?.data?.newCreateDisbursement;

      // if failed
      if (result?.status === 'FAILED' || result?.status === 'BLOCKED') {
        throw new Error(result.message);
      }

      //segment tracking
      window.analytics.track('Create Bill Payment');
      onClose();
      onSuccess(result?.spendingId);
    } catch (err) {
      setSubmitError({ status: true, message: err.message });
      window.analytics.track('Create Bill Payment - ERROR');
      return result;
    }
  };

  const backToForm = () => {
    setIsOtpSent(false);
    setSubmitError({ status: false });
  };

  if (isErr) return `Error!`;

  return (
    <Modal isOpen={isOpen} fade size="lg">
      {currentUser?.kyc?.status !== 'verify' && (
        <div>
          <div className="modal-header">
            <h5 className="modal-title h2">Buat Pembayaran Invoice / Tagihan</h5>
            <Button className="close" color="" onClick={onClose}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <Alert color="warning">
              Untuk melakukan pembayaran invoice / tagihan, silahkan verifikasi data diri anda di{' '}
              <Link to="/settings?active=verification">Pengaturan {'>'} Verifikasi</Link>
            </Alert>
          </ModalBody>
        </div>
      )}

      {showOtp && (
        <div>
          <div className="modal-header">
            <h5 className="modal-title h2">Konfirmasi Pembayaran</h5>
            <Button
              className={`close ${loadingDisb ? 'cursor-not-allowed' : ''}`}
              color=""
              disabled={loadingDisb}
              onClick={onClose}
            >
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            <OTPConfirmation
              data={[paymentDetail]}
              onClose={backToForm}
              onSubmitOTP={createBillPayment}
              onRequestOTP={handleRequestOtp}
              submitError={submitError}
              disbursementType="INVOICE"
              resetSubmitError={() => setSubmitError({ status: false })}
            />
          </ModalBody>
        </div>
      )}

      {showForm && (
        <>
          <div className="modal-header">
            <h5 className="modal-title h2">Buat Pembayaran Invoice / Tagihan</h5>
            <Button className="close" color="" onClick={onClose}>
              <Icon name="x" />
            </Button>
          </div>
          <ModalBody>
            {/* <p>
              <small>Upload file pdf Invoice anda disini untuk sistem pendeteksian otomatis.</small>
            </p> */}
            <MayarAlert
              isError={submitError?.status || isAmountExceedLimit?.status}
              message={submitError?.message || isAmountExceedLimit?.message}
              className="mb-10"
            />
            <Form onSubmit={handleSubmit(onSubmit)} id="create-bill-payment">
              <FormGroup>
                <Label for="fileId">File Invoice</Label>
                <Controller
                  name="fileId"
                  id="fileId"
                  control={control}
                  defaultValue={paymentDetail?.fileId || null}
                  render={({ onChange }) => (
                    <Dropzone
                      setFileId={onChange}
                      maxFiles={1}
                      multiple={false}
                      setNotAllowed={setNotAllowed}
                      isError={!!errors.fileId || notAllowed}
                      customLabel={'Drop pdf invoice'}
                    />
                  )}
                />
                <FormFeedback className="d-block">{errors.fileId?.message}</FormFeedback>
                {notAllowed && (
                  <FormFeedback className="d-block">Nama file tidak boleh menggunakan special character!</FormFeedback>
                )}
                <small>Ukuran file maksimal 1GB.</small>
              </FormGroup>
              <hr />
              <FormGroup>
                <Label for="invoiceNumber">Nomor Invoice</Label>
                <Input
                  type="text"
                  name="invoiceNumber"
                  id="invoiceNumber"
                  defaultValue={paymentDetail?.invoiceNumber}
                  invalid={errors.invoiceNumber}
                  innerRef={register()}
                />
                <FormFeedback>{errors.invoiceNumber?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="accountName">Nama Penerima*</Label>
                <Input
                  type="text"
                  name="accountName"
                  id="accountName"
                  invalid={errors.accountName}
                  defaultValue={paymentDetail?.accountName}
                  innerRef={register({
                    required: validator.required
                  })}
                />
                <FormFeedback>{errors.accountName?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="currency">Mata Uang*</Label>
                <Controller
                  name="currency"
                  control={control}
                  defaultValue={currency}
                  rules={{ required: validator.required }}
                  render={({ onChange, value }) => (
                    <Select
                      inputId="currency"
                      value={value}
                      options={currencyOptions}
                      styles={Boolean(errors.currency) ? CustomStylesInvalid : CustomStyles}
                      className={Boolean(errors.currency) ? 'is-invalid' : ''}
                      placeholder="Pilih Mata Uang"
                      onChange={e => {
                        onChange(e);
                        setCurrency(e);
                      }}
                    />
                  )}
                />
                <FormFeedback>{errors.currency?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="amount">Jumlah*</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    name="amount"
                    id="amount"
                    invalid={Boolean(errors.amount)}
                    defaultValue={paymentDetail?.amount}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1000),
                      max: validator.max(500000000)
                    })}
                  />
                  <FormFeedback>{errors?.amount?.message}</FormFeedback>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="invoiceExpiredAt">Tanggal Jatuh Tempo (opsional)</Label>
                <div className="mb-1 date-block">
                  <DatePicker
                    showTimeSelect
                    placeholderText="Pilih Tanggal dan Jam"
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={invoiceExpiredAt ? new Date(invoiceExpiredAt) : null}
                    minDate={new Date()}
                    className="rui-datetimepicker form-control"
                    onChange={d => setInvoiceExpiredAt(d)}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="bankCountry">Negara Bank Penerima</Label>
                <Controller
                  name="bankCountry"
                  control={control}
                  defaultValue={bankCountry}
                  rules={{ required: validator.required }}
                  render={({ onChange, value }) => (
                    <Select
                      inputId="bankCountry"
                      value={value}
                      options={bankCountryOptions}
                      styles={Boolean(errors.bankCountry) ? CustomStylesInvalid : CustomStyles}
                      className={Boolean(errors.bankCountry) ? 'is-invalid' : ''}
                      placeholder="Pilih Negara Bank"
                      onChange={e => {
                        onChange(e);
                        setBankCountry(e);
                      }}
                    />
                  )}
                />
                <FormFeedback>{errors.bank?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="bank">Nama Bank Penerima*</Label>
                <Controller
                  name="bank"
                  control={control}
                  defaultValue={bankAccount || ''}
                  rules={{ required: validator.required }}
                  render={({ onChange, value }) => (
                    <Select
                      inputId="bank"
                      value={value}
                      options={bankOptions}
                      styles={Boolean(errors.bank) ? CustomStylesInvalid : CustomStyles}
                      className={Boolean(errors.bank) ? 'is-invalid' : ''}
                      placeholder="Pilih Bank"
                      onChange={e => {
                        onChange(e);
                        setBankAccount(e);
                      }}
                    />
                  )}
                />
                <FormFeedback>{errors.bank?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="accountNumber">Nomor Rekening Penerima*</Label>
                <Input
                  type="text"
                  name="accountNumber"
                  id="accountNumber"
                  defaultValue={paymentDetail?.accountNumber}
                  invalid={Boolean(errors.accountNumber)}
                  innerRef={register({
                    required: validator.required
                  })}
                />
                <FormFeedback>{errors.accountNumber?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="accountType">Tipe Rekening*</Label>
                <Controller
                  name="accountType"
                  control={control}
                  defaultValue={accountType ? accountType : ''}
                  rules={{ required: validator.required }}
                  render={({ onChange, value }) => (
                    <Select
                      inputId="accountType"
                      value={value}
                      options={bankAccountTypeOptions}
                      styles={Boolean(errors.accountType) ? CustomStylesInvalid : CustomStyles}
                      className={Boolean(errors.accountType) ? 'is-invalid' : ''}
                      placeholder="Pilih Tipe Rekening"
                      onChange={e => {
                        onChange(e);
                        setAccountType(e);
                      }}
                    />
                  )}
                />
                <FormFeedback>{errors.accountType?.message}</FormFeedback>
              </FormGroup>
              <hr />
              <FormGroup>
                <Label for="email">Email Penerima (opsional)</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  defaultValue={paymentDetail?.email}
                  invalid={errors.email}
                  innerRef={register()}
                />
                <FormFeedback>{errors.email?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="invoiceCategory">Kategori (opsional)</Label>
                <Controller
                  name="invoiceCategory"
                  control={control}
                  defaultValue={invoiceCategory || ''}
                  render={({ onChange, value }) => (
                    <Select
                      inputId="invoiceCategory"
                      value={value}
                      options={paymentCategoryOptions}
                      styles={Boolean(errors.invoiceCategory) ? CustomStylesInvalid : CustomStyles}
                      className={Boolean(errors.invoiceCategory) ? 'is-invalid' : ''}
                      placeholder="Pilih Kategori"
                      onChange={e => {
                        onChange(e);
                        setInvoiceCategory(e);
                      }}
                    />
                  )}
                />
                <FormFeedback>{errors.invoiceCategory?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="tax">Pajak (opsional)</Label>
                <Input
                  type="number"
                  name="tax"
                  id="tax"
                  defaultValue={paymentDetail?.tax}
                  invalid={errors.tax}
                  innerRef={register()}
                />
                <FormFeedback>{errors.tax?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="notes">Catatan (opsional)</Label>
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  defaultValue={paymentDetail?.notes}
                  invalid={errors.notes}
                  innerRef={register()}
                />
                <FormFeedback>{errors.notes?.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  id="isEmailSentAfterSuccess"
                  checked={isEmailSentAfterSuccess}
                  label="Kirim Email Pemberitahuan Setelah Pembayaran Sukses"
                  name="isEmailSentAfterSuccess"
                  type="switch"
                  color="primary"
                  onChange={e => setIsEmailSentAfterSuccess(e.target.checked)}
                  innerRef={register()}
                />
              </FormGroup>
              {errorOtp && (
                <Alert color="danger" className="mb-20">
                  Terjadi kesalahan saat berusaha mengirim kode konfirmasi. Silahkan refresh atau periksa jaringan Anda.
                </Alert>
              )}
              {validation && (
                <Alert color="danger" className="mb-20">
                  {validation}
                </Alert>
              )}
              <SubmitButton
                size="lg"
                block
                color="brand"
                className="text-center"
                type="submit"
                loading
                isLoading={loadingOtp || loadingFetchBalance}
                text="Buat Pembayaran"
              />
            </Form>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

ModalBillPayment.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ModalBillPayment;
