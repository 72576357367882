import React, { useState, useEffect } from 'react';
import _, { debounce } from 'lodash';
import { useMutation, useQuery, useApolloClient, useLazyQuery } from '@apollo/client';
import randomstring from 'randomstring';
import AsyncSelect from 'react-select/async';
import { useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Alert
} from 'reactstrap';
import {
  GQL_DISCOUNT_WITH_COUPONS_CREATE,
  GQL_ACTIVE_PAYMENT_LINK_GET,
  GQL_COUPONS_FILTERED_LIST,
  GQL_COUPONS_UPDATE
} from '../../gqls';
import { createProductListOptions, validator } from '../../utils';
import DatePicker from '../date-time-picker';
import Icon from '../icon';
import { CustomStyles } from '../custom-styles/react-select-styles';
import SubmitButton from '../submit-button';
import { useDispatch } from 'react-redux';
import { updateDiscount } from '../../actions/discount';

const getInitialEligibleCustomer = data => {
  if (data && data?.eligibleCustomerType) return data.eligibleCustomerType;

  return 'all';
};

const ModalBodyDiscount = ({ isOpen = false, onClose, onSuccess, data, coup }) => {
  if (!isOpen) return null;
  const dispatch = useDispatch();
  const client = useApolloClient();
  const isUpdate = data || coup ? true : false;
  const { register, handleSubmit, errors } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [selectProducts, setSelectProducts] = useState([]);
  const [isAllProduct, setIsAllProduct] = useState(true);
  const [defaultProductOptions, setDefaultProductOptions] = useState(true);
  const [isPercentage, setIsPercentage] = useState(true);
  const [isFixNumber, setIsFixNumber] = useState(false);
  const [maxDiscount, setMaxDiscount] = useState(() => (isPercentage ? 100 : null));
  const [isMultipleUse, setIsMultipleUse] = useState(true);
  const [date, setDate] = useState(null);
  const [insertDiscount, { data: newDiscount, loading: loadingNewDiscount }] = useMutation(
    GQL_DISCOUNT_WITH_COUPONS_CREATE
  );
  const [getCoupons, { data: coupons }] = useLazyQuery(GQL_COUPONS_FILTERED_LIST, {
    fetchPolicy: 'no-cache'
  });
  const [editCoupons, { data: editedCoupons, loading: loadingUpdateCoupon }] = useMutation(GQL_COUPONS_UPDATE);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [codeDiscount, setCodeDiscount] = useState(isUpdate ? coup?.items[0]?.code : '');
  const [successCoupon, setSuccessCoupon] = useState('');
  const [couponError, setCouponError] = useState(false);
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [eligibleCustomerType, setEligibleCustomerType] = useState(() => getInitialEligibleCustomer(data));
  const today = new Date();
  const tomorrow = new Date(today);

  // const {data : subs} = useQuery(GQL_ACTIVE_SUBSCRIPTION_GET, {
  //   variables: {
  //     search: {
  //       "status":  [{
  //         "operator": "eq","value": "active"
  //       }]
  //     }
  //   },
  // });

  const paramSearchPaymentLinks = {
    limit: 10,
    search: {
      status: [
        { operator: 'not', value: 'closed' },
        { operator: 'not', value: 'deleted' },
        { operator: 'not', value: 'archived' },
        { operator: 'not', value: 'paid' }
      ],
      type: [
        { operator: 'not', value: 'payment_request' },
        { operator: 'not', value: 'membership_payment' },
        { operator: 'not', value: 'fundraising' },
        // { operator: "not", value: "membership" }
      ]
    },
    sortField: 'createdAt',
    sortDirection: 'DESC'
  };

  const { loading: paymentLinksLoading } = useQuery(GQL_ACTIVE_PAYMENT_LINK_GET, {
    variables: paramSearchPaymentLinks,
    onCompleted: dataLp => {
      setDefaultProductOptions(createProductListOptions(dataLp?.getPaymentLinkFilteredList));
    }
  });

  const generateCoupon = () => {
    setCouponError(false);
    setCouponSuccess(false);
    setSuccessMessage('');
    setErrorMessage('');
    setCodeDiscount(
      randomstring.generate({
        length: 7,
        charset: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
      })
    );
  };

  useEffect(() => {
    const dataCoupons = _.get(coupons, 'getCouponsFilteredList');
    if (dataCoupons) {
      if (codeDiscount.length > 0 && codeDiscount.length < 4) {
        setCouponError(true);
        setCouponSuccess(false);
        setErrorMessage('Kode kupon minimal harus 4 karakter!');
      } else if (dataCoupons.length < 1) {
        setCouponError(false);
        setCouponSuccess(true);
        setSuccessCoupon(codeDiscount);
        setIsBtnDisabled(false);
        setSuccessMessage('Kode kupon  ' + codeDiscount + ' dapat digunakan');
      } else if (isUpdate && codeDiscount === coup?.items[0]?.code) {
        setCouponError(false);
        setCouponSuccess(false);
        setSuccessCoupon(codeDiscount);
        setSuccessMessage('');
        setIsBtnDisabled(true);
      } else {
        setCouponError(true);
        setCouponSuccess(false);
        setIsBtnDisabled(false);
        setErrorMessage('Kode kupon telah digunakan. Ubah agar kode kupon unik!');
      }
    }
  }, [coupons]);

  useEffect(() => {
    if (codeDiscount.length > 3 && /^\S+$/.test(codeDiscount)) {
      getCoupons({
        variables: {
          search: {
            code: [{ operator: 'eq', value: codeDiscount }]
          }
        }
      });
    } else if (codeDiscount.length > 0 && codeDiscount.length < 4) {
      setCouponError(true);
      setCouponSuccess(false);
      setErrorMessage('Kode kupon minimal harus 4 karakter!');
    } else if (codeDiscount.length > 0 && !/^\S+$/.test(codeDiscount)) {
      setCouponError(true);
      setCouponSuccess(false);
      setErrorMessage('Kode kupon tidak boleh mengandung spasi!');
    }

    if (coupons && codeDiscount === successCoupon && /^\S+$/.test(codeDiscount)) {
      setCouponSuccess(true);
      setCouponError(false);
    }
  }, [codeDiscount]);

  const _loadSuggestions = (keyword, callback) => {
    if (!keyword) {
      return callback([]);
    }

    paramSearchPaymentLinks.search.name = [{ operator: 'like', value: `%${keyword}%` }];
    client
      .query({
        query: GQL_ACTIVE_PAYMENT_LINK_GET,
        variables: paramSearchPaymentLinks,
        context: {
          queryDeduplication: false
        }
      })
      .then(resp => callback(createProductListOptions(resp.data?.getPaymentLinkFilteredList)));
  };
  const loadSuggestions = debounce(_loadSuggestions, 500);

  const onSubmit = async values => {
    if (codeDiscount.length === 0 && isMultipleUse) {
      setCouponError(true);
      setCouponSuccess(false);
      setErrorMessage('Wajib diisi');
      return;
    }
    if (couponError) return;
    if (values.couponLimit) {
      values.couponLimit = parseInt(values.couponLimit);
    } else {
      delete values.couponLimit;
    }

    if (values.noCoupon) values.noCoupon = parseInt(values.noCoupon);
    if (values.value) values.value = parseFloat(values.value);
    if (date !== null) setDate(date);

    try {
      if (isUpdate) {
        editCoupons({
          variables: {
            input: {
              id: coup?.items[0]?.id,
              code: codeDiscount
            }
          }
        });
      } else {
        const queryCoupon = {
          code: codeDiscount,
          expiredAt: date,
          limit: values.couponLimit,
          type: 'reusable',
          isActive: true
        };

        let discountType;

        if (isPercentage) {
          discountType = 'percentage';
        } else if (isFixNumber) {
          discountType = 'monetary';
        }

        const queryDiscount = {
          discountType,
          eligibleCustomerType,
          minimumPurchase: parseFloat(values.minimumPurchase),
          //isLifeTime,
          name: values.name,
          value: values.value,
          status: 'active',
          expiredAt: date,
          totalCoupons: values.couponLimit,
          discountProductType: isAllProduct ? 'all' : 'selected'
        };
        const queryProducts = [];

        if (queryDiscount.discountProductType === 'selected') {
          for (let i = 0; i < selectProducts.length; i++) {
            queryProducts.push({
              paymentLinkId: selectProducts[i].type === 'payment_link' ? selectProducts[i].value : null,
              productType: selectProducts[i].type,
              subscriptionId: selectProducts[i].type === 'subscription' ? selectProducts[i].value : null,
              discountProductType: 'selected'
            });
          }
        }

        const inputDiscount = {
          discount: queryDiscount,
          reusableCoupons: queryCoupon,
          products: queryProducts
        };

        const bulkCoupons = [];
        if (!isMultipleUse) {
          for (let i = 0; i < values.noCoupon; i++) {
            bulkCoupons.push({
              code: randomstring.generate({
                length: 7,
                charset: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
              }),
              expiredAt: date,
              limit: 1,
              type: 'onetime',
              isActive: true
            });
          }
          delete inputDiscount.reusableCoupons;
          inputDiscount.onetimeCoupons = bulkCoupons;
          inputDiscount.discount.totalCoupons = values.noCoupon;
        }

        await insertDiscount({ variables: inputDiscount });
        //segment tracking
        window.analytics.track('Create Discount', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Discount - ERROR', values);
    }
  };

  useEffect(() => {
    if (editedCoupons?.editCoupons?.id) {
      dispatch(updateDiscount(Math.random()));
      // onClose();
    }
  }, [editedCoupons]);

  if (newDiscount && newDiscount.insertDiscountWithCoupons) onSuccess(newDiscount.insertDiscountWithCoupons.id);

  return (
    <Modal isOpen={isOpen} fade size="lg">
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Update' : 'Buat'} Diskon</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}

        {isUpdate && isMultipleUse ? (
          <Form onSubmit={handleSubmit(onSubmit)} id="update-payment-link">
            <FormGroup>
              <Label for="couponCode">Kode Kupon*</Label>
              <div className="row">
                <div className="col-12">
                  <Input
                    type="text"
                    name="couponCode"
                    id="couponCode"
                    onChange={e => setCodeDiscount(e.target.value)}
                    value={codeDiscount}
                    invalid={couponError}
                    valid={couponSuccess}
                    innerRef={register({
                      required: validator.required,
                      minLength: validator.minLength(4)
                    })}
                  />
                  {couponSuccess ? (
                    <FormFeedback valid>{successMessage}</FormFeedback>
                  ) : (
                    <FormFeedback>{errorMessage}</FormFeedback>
                  )}
                </div>
              </div>
            </FormGroup>
            <SubmitButton
              size="lg"
              block
              color="brand"
              type="submit"
              className="text-center"
              loading
              isDisabled={isBtnDisabled}
              isLoading={loadingUpdateCoupon}
              text={'Update Diskon'}
            />
          </Form>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
            <FormGroup>
              <Label for="name">Nama Diskon*</Label>
              <Input
                type="text"
                name="name"
                id="name"
                invalid={errors.name}
                innerRef={register({
                  required: validator.required,
                  minLength: validator.minLength(5)
                })}
              />
              <FormFeedback>{errors.name?.message}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="jumlah3">Untuk Produk*</Label>
              <Input
                id="forProduct"
                name="forProduct"
                type="select"
                defaultValue={isAllProduct}
                onChange={() => setIsAllProduct(!isAllProduct)}
              >
                <option value={true}>Semua Produk</option>
                <option value={false}>Pilih Produk</option>
              </Input>
            </FormGroup>
            {/* JIKA PILIH PRODUK */}
            {!isAllProduct && (
              <FormGroup>
                <Label for="jumlah3">Pilih Produk</Label>
                <AsyncSelect
                  loadOptions={loadSuggestions}
                  defaultOptions={defaultProductOptions}
                  styles={CustomStyles}
                  isMulti
                  placeholder="Ketik Nama Produk..."
                  onChange={setSelectProducts}
                  isDisabled={paymentLinksLoading}
                  isLoading={paymentLinksLoading}
                />
              </FormGroup>
            )}
            {/* JIKA PILIH PRODUK END */}
            <FormGroup>
              <Label for="jumlah3">Tipe Diskon*</Label>
              <Input
                id="discountType"
                name="discountType"
                type="select"
                defaultValue={'percentage'}
                onChange={e => {
                  const type = e.target.value;

                  if (type === 'percentage') {
                    setIsFixNumber(false);
                    setMaxDiscount(100);
                    setIsPercentage(true);
                  } else if (type === 'monetary') {
                    setIsPercentage(false);
                    setMaxDiscount(null);
                    setIsFixNumber(true);
                  }
                }}
              >
                <option value="percentage">Persentase</option>
                <option value="monetary">Nominal</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="value">Besaran*</Label>
              <InputGroup>
                {!isPercentage && (
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Rp</InputGroupText>
                  </InputGroupAddon>
                )}

                <Input
                  type="number"
                  name="value"
                  id="value"
                  invalid={errors.value ? true : false}
                  min={0}
                  max={maxDiscount}
                  innerRef={register({
                    required: validator.required,
                    min: validator.min(0),
                    max: validator.max(maxDiscount)
                  })}
                />
                {isPercentage && (
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                )}

                <FormFeedback>{errors.value?.message}</FormFeedback>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label for="minimumPurchase">Minimum Pembelian</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="minimumPurchase"
                  id="minimumPurchase"
                  invalid={errors.minimumPurchase ? true : false}
                  min={0}
                  innerRef={register({
                    min: validator.min(0)
                  })}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="couponType">Tipe Kupon*</Label>
              <Input
                id="couponType"
                name="couponType"
                type="select"
                defaultValue={isMultipleUse}
                onChange={() => setIsMultipleUse(!isMultipleUse)}
              >
                <option value={true}>Berulang</option>
                <option value={false}>Sekali Pakai</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="eligibleCustomerType">Untuk Pelanggan*</Label>
              <Input
                id="eligibleCustomerType"
                name="eligibleCustomerType"
                type="select"
                defaultValue={eligibleCustomerType}
                onChange={e => setEligibleCustomerType(e.target.value)}
              >
                <option value="all">Semua Pelanggan</option>
                <option value="new">Pelanggan Baru</option>
                <option value="existing">Pelanggan Lama</option>
              </Input>
            </FormGroup>

            {/* JIKA SEKALI PAKAI */}
            {!isMultipleUse && (
              <FormGroup>
                <Label for="noCoupon">Jumlah Kupon (Auto generated csv)*</Label>
                <Input
                  type="number"
                  name="noCoupon"
                  id="noCoupon"
                  min={0}
                  max={50}
                  invalid={errors.noCoupon}
                  innerRef={register({
                    required: validator.required,
                    min: validator.min(0),
                    max: validator.max(50)
                  })}
                />
                <FormFeedback>{errors.noCoupon?.message}</FormFeedback>
              </FormGroup>
            )}
            {/* JIKA SEKALI PAKAI END */}

            {/* JIKA MULTIPLE */}
            {isMultipleUse && (
              <div>
                <FormGroup>
                  <Label for="couponCode">Kode Kupon*</Label>
                  <div className="row">
                    <div className="col-8">
                      <Input
                        type="text"
                        name="couponCode"
                        id="couponCode"
                        onChange={e => setCodeDiscount(e.target.value)}
                        value={codeDiscount}
                        invalid={couponError}
                        valid={couponSuccess}
                        innerRef={register({
                          required: validator.required,
                          minLength: validator.minLength(4)
                        })}
                      />
                      {couponSuccess ? (
                        <FormFeedback valid>{successMessage}</FormFeedback>
                      ) : (
                        <FormFeedback>{errorMessage}</FormFeedback>
                      )}
                    </div>
                    <div className="col-4">
                      <Button className="text-center" onClick={generateCoupon} block outline color="dark">
                        Generate
                      </Button>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="couponLimit">Batas Pemakaian / Limit*</Label>
                  <Input
                    type="number"
                    name="couponLimit"
                    id="couponLimit"
                    min={0}
                    invalid={errors.couponLimit ? true : false}
                    innerRef={register({
                      // required: validator.required,
                      min: validator.min(0)
                    })}
                  />
                  <FormFeedback>{errors.couponLimit?.message}</FormFeedback>
                  <small>Kosongkan jika tidak terbatas</small>
                </FormGroup>
              </div>
            )}
            {/* JIKA MULTIPLE END */}

            <FormGroup>
              <Label for="kadaluarsa4">Tanggal Kadaluarsa</Label>
              <div className="mb-1">
                <DatePicker
                  placeholderText="Pilih tanggal atau kosongkan"
                  dateFormat="yyyy/MM/dd"
                  selected={date ? new Date(date) : null}
                  minDate={tomorrow.setDate(tomorrow.getDate() + 1)}
                  className="rui-datetimepicker form-control w-auto"
                  onChange={d => setDate(d)}
                />
              </div>
              <small>Kosongkan jika kode kupon ingin aktif selamanya</small>
            </FormGroup>
            {/* <FormGroup>
            <Label for="discountLength">Lama Diskon (Hanya untuk paket berlangganan)</Label>
              <Input
                id="discountLength"
                name="discountLength"
                type="select"
                defaultValue={isLifeTime}
                onChange={(e) => {onChangeDiscLength(e.target.value)}}
              >
                <option value={false}>Sekali</option>
                <option value={true}>Seterusnya</option>
              </Input>
            </FormGroup> */}
            <SubmitButton
              size="lg"
              block
              color="brand"
              type="submit"
              className="text-center"
              loading
              isLoading={loadingNewDiscount}
              text={'Buat Diskon'}
            />
          </Form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyDiscount;
