import { gql } from '@apollo/client';

export const GQL_CREATE_CHECKOUT_SETTINGS = gql`
	mutation insertCheckoutSettingsPaymentLink($input: CheckoutSettingsInput) {
    insertCheckoutSettingsPaymentLink(input: $input) {
			id
			message
    }
  }
`;

export const GQL_UPDATE_CHECKOUT_SETTINGS = gql`
	mutation editCheckoutSettingsPaymentLink($input: CheckoutSettingsInput) {
		editCheckoutSettingsPaymentLink(input: $input) {
			id
			message
		}
	}
`;
