import { gql } from '@apollo/client';

export const GQL_PHYSICAL_PRODUCT_CREATE = gql`
  mutation insertPaymentLinkBySlug($input: PaymentLinkInput!) {
    insertPaymentLinkBySlug(input: $input) {
      id
      amount
      redirectUrl
      description
      expiredAt
      createdAt
      updatedAt
      qty
      link
      status
      type
      name
      category
      multipleImageId
      multipleImage {
        title
        url
        id
      }
      order {
        discountAmount
        width
        length
        height
        weight
        vendor
        sku
        wholesaleAmount
      }
      user {
        accountId
        account {
          name
          logo {
            url
          }
        }
        id
        email
        name
      }
    }
  }
`;

export const GQL_PHYSICAL_PRODUCT_UPDATE = gql`
  mutation editPaymentLinkBySlug($input: PaymentLinkInput!) {
    editPaymentLinkBySlug(input: $input) {
      id
      amount
      customerId
      description
      expiredAt
      limit
      link
      name
      redirectUrl
      subscriptionId
      type
      user {
        accountId
        account {
          name
          logo {
            url
          }
        }
        id
        email
        name
      }
    }
  }
`;

export const GQL_PHYSICAL_PRODUCT_PAGE = gql`
  query getOrderPage(
    $page: Int
    $pageSize: Int
    $search: OrderSearchInput
    $searchAny: OrderSearchInput
    $sortDirection: SortDirection
    $sortField: OrderSortField
  ) {
    getOrderPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        createdAt
        discountAmount
        height
        length
        paymentLinkId
        sku
        updatedAt
        userId
        variant
        vendor
        weight
        wholesaleAmount
        width
        id
      }
      nextPage
      offset
      page
      prevPage
      total
      totalPage
    }
  }
`;

export const GQL_PHYSICAL_PRODUCT_GET = gql`
  query getPaymentLinkFilteredList($search: PaymentLinkSearchInput, $limit: Int = 1) {
    getPaymentLinkFilteredList(search: $search, limit: $limit) {
      id
      amount
      customerId
      description
      startAt
      expiredAt
      category
      limit
      link
      name
      redirectUrl
      subscriptionId
      type
      qty
      status
      notes
      reminderWellcomeId
      urlParamTracking
      affiliateCommission
      affiliateCommissionType
      form {
        fieldObject
        id
        name
      }
      user {
        accountId
        account {
          name
          logo {
            url
          }
        }
        id
        email
        name
      }
      order {
        discountAmount
        height
        width
        length
        weight
        sku
        vendor
        wholesaleAmount
        variant
      }
      multipleImage {
        url
        id

        createdAt
        updatedAt
      }
      checkoutSettings {
        id
        buttonName
        buttonNote
        discountButton
        couponOption
        paymentLinkId
        termOption
        termUrl
        isPopupRecentTransaction
      }
    }
  }
`;

export const GQL_TRACK_ORDER_DETAIL_GET = gql`
  query getDetailOrder($orderId: String) {
    getDetailOrder(orderId: $orderId) {
      status
      tracking {
        createdBy
        createdDate
        updatedBy
        updatedDate
        id
        name
        description
      }
      detail {
        id
        externalId
        labelChecksum
        isHubless
        isActive
        isLabelPrinted
        shipmentStatus {
          createdBy
          createdDate
          updatedBy
          updatedDate
          id
          name
          description
        }
      }
    }
  }
`;
