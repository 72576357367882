import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Button, Modal, ModalBody } from 'reactstrap';

import { GQL_GET_OTP, NEW_CREATE_DISBURSEMENT } from '../../../gqls';
import MayarAlert from '../../alert/alert';
import Icon from '../../icon';
import Form from './components/form';
import OTPConfirmation from '../../otp-confirmation';
import { formatRp, getDisbursementFee, sanitizeDOM } from '../../../utils';
import { getContentString } from '../../rich-text-editor';

const recreateDisbursementPayload = (values, currentUser) => {
  delete values.transferType;
  const transferDescription = values.transferDescription
    ? sanitizeDOM(getContentString(values.transferDescription))
    : '';
  return {
    ...values,
    status: 'ON_PROCESS',
    type: 'TRANSFER',
    bank: values.bank?.value,
    invoiceCategory: values.invoiceCategory.value,
    bankCountry: values.bankCountry?.value,
    accountType: values.accountType?.value || null,
    currency: values.currency?.value,
    tax: parseInt(values.tax),
    amount: parseFloat(values.amount),
    // transferDescription: values.transferDescription || `Payment for transfer from ${currentUser.account.name}`,
    transferDescription:
      transferDescription === '' ? `Payment for transfer from ${currentUser.account.name}` : transferDescription
  };
};

const ModalMoneyTransfer = ({ isOpen, onClose, onSuccess }) => {
  const form = useForm();
  const currentUser = useSelector(state => state.currentUser);
  // const disbursementFee = parseFloat(`${process.env.REACT_APP_MAYAR_DISBURSEMENT_FEE}`);
  const [disbursementFee] = useState(() => getDisbursementFee());
  const [submitError, setSubmitError] = useState({ status: false, message: '' });
  const [isAmountExceedLimit, setIsAmountExceedLimit] = useState({ status: false, message: '' });
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [validation, setValidation] = useState('');
  // const [maxDisbursement, setMaxDisbursement] = useState(0);
  const [loadingFetchBalance, setLoadingFetchBalance] = useState(false);
  const [dataBalance, setDataBalance] = useState({});
  const { balanceActive, balancePending, balance } = dataBalance;
  const [maxAmount, setMaxAmount] = useState(balanceActive);
  const [isErr, setIsErr] = useState(false);

  const [createDisbursement, { loading: loadingDisb }] = useMutation(NEW_CREATE_DISBURSEMENT);
  const [getOTP, { error: OTPerror, loading: loadingOTP }] = useLazyQuery(GQL_GET_OTP, {
    fetchPolicy: 'network-only'
  });

  /* Check balance */
  const fetchBalance = useCallback(() => {
    setLoadingFetchBalance(true);
    axios
      .get(`${process.env.REACT_APP_PROXY}/xendit-balance`)
      .then(res => {
        setLoadingFetchBalance(false);
        setDataBalance(res.data);
      })
      .catch(() => {
        setIsErr(true);
        setLoadingFetchBalance(false);
      });
  }, []);

  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  const setBalance = () => {
    if (balanceActive) {
      setMaxAmount(balanceActive - disbursementFee);
      // if (balanceActive >= disbursementFee) {
      // setMaxDisbursement(balanceActive - disbursementFee);
      // }
    }
  };

  useEffect(() => {
    setBalance();
  }, [balanceActive, balancePending, balance]);

  const onRequestOTP = () => {
    const { amount, accountNumber, accountName, bank } = recreateDisbursementPayload(form.getValues(), currentUser);

    getOTP({
      variables: {
        input: {
          bankAccountName: accountName,
          bankAccountNumber: accountNumber,
          bankAccount: bank,
          transferAmount: parseFloat(amount),
          otpType: 'TRANSFER'
        }
      }
    });
    setIsOTPSent(true);
  };

  const onSubmitOTP = async otp => {
    const values = form.getValues();
    let result = { status: 'SUCCESS' };

    try {
      const response = await createDisbursement({
        variables: {
          input: {
            otp,
            type: 'TRANSFER',
            amount: parseFloat(values.amount),
            disbursement: recreateDisbursementPayload(values, currentUser)
          }
        }
      });

      result = response?.data?.newCreateDisbursement;

      if (result?.status === 'FAILED' || result?.status === 'BLOCKED') {
        throw new Error(result.message);
      }

      //segment tracking
      window.analytics.track('Create Money Transfer', values);
      onClose();
      onSuccess(result?.spendingId);
    } catch (error) {
      setSubmitError({ status: true, message: error.message });
      window.analytics.track('Create Money Transfer - ERROR', values);
      return result;
    }
  };

  const onSubmit = values => {
    if (maxAmount <= 0) {
      setValidation('Jumlah saldo aktif tidak mencukupi');
      return;
    }

    if (parseFloat(values.amount) >= 10000) {
      if (parseFloat(values.amount) <= maxAmount) {
        setValidation('');
      } else {
        setValidation(
          'Jumlah maksimal saldo aktif yang dapat digunakan adalah ' + formatRp(maxAmount > 0 ? maxAmount : 0)
        );
        setTimeout(() => {
          setValidation('');
        }, 5000);
        return;
      }
    }

    onRequestOTP();
  };

  const { formState, getValues } = form;
  const { isValid, isSubmitting } = formState;
  useEffect(() => {
    const { amount } = getValues() || {};

    setIsAmountExceedLimit({
      status: Boolean(parseFloat(amount) > 500000000) && !isValid,
      message:
        parseFloat(amount) > 500000000 && !isValid
          ? 'Hubungi customer service untuk menaikkan limit per-transfer anda.'
          : ''
    });
  }, [isSubmitting]);

  if (isErr) return `Error!`;

  return (
    <Modal isOpen={isOpen} fade size="lg">
      <div className="modal-header">
        <h5 className="modal-title h2">{isOTPSent ? 'Konfirmasi Kirim Uang/Top Up' : 'Buat Pengiriman Uang/Top Up'}</h5>
        <Button
          className={`close ${loadingDisb ? 'cursor-not-allowed' : ''}`}
          color=""
          disabled={loadingDisb}
          onClick={onClose}
        >
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {currentUser?.kyc?.status !== 'verify' ? (
          <Alert color="warning">
            Untuk melakukan pengiriman uang / top up, silahkan verifikasi data diri anda di{' '}
            <Link to="/settings?active=verification">Pengaturan {'>'} Verifikasi</Link>
          </Alert>
        ) : (
          <>
            <MayarAlert
              isError={Boolean(isAmountExceedLimit?.status)}
              message={isAmountExceedLimit?.message}
              className="mb-20"
            />
            {isOTPSent && (
              <OTPConfirmation
                data={[recreateDisbursementPayload(form?.getValues(), currentUser)]}
                onClose={() => {
                  setIsOTPSent(false);
                  setSubmitError({ status: false });
                }}
                onSubmitOTP={onSubmitOTP}
                onRequestOTP={onRequestOTP}
                submitError={submitError}
                disbursementType="TRANSFER"
                resetSubmitError={() => setSubmitError({ status: false })}
              />
            )}
            <Form
              useForm={() => form}
              onSubmit={onSubmit}
              className={isOTPSent ? 'd-none' : ''}
              isError={OTPerror}
              isLoading={loadingOTP}
              validation={validation}
              loadingFetchBalance={loadingFetchBalance}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

ModalMoneyTransfer.defaultProps = {
  onSuccess: () => {}
};

ModalMoneyTransfer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
};

export default ModalMoneyTransfer;
