import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { EditorState } from 'draft-js';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormFeedback,
  Row,
  CustomInput,
  Col
} from 'reactstrap';
import {
  GQL_PAYMENTLINK_CREATE_BY_SLUG,
  GQL_PAYMENTLINK_UPDATE_BY_SLUG,
  GQL_GET_USER_CONTENTS,
  GQL_INSERT_USER_CONTENT_FILE,
  GQL_UPDATE_USER_CONTENT_FILE,
  GQL_GET_CONTENT_FILE
} from '../../gqls';
import {
  validator,
  paymentLinkUrl,
  sanitizeDOM,
  REGEX_SLUG,
  paymentTypeOptions,
  slug,
  getInitialDownloadableValue,
  secondsToHours
} from '../../utils';
import { updatePaymentLinks } from '../../actions/payment-links';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import Icon from '../icon';
import DatePicker from '../date-time-picker';
import Dropzone from '../dropzone';
import LoadingAnimation from '../loading-animation';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import MultipleImageInput from '../multiple-image-input';

const ModalBodyAudiobook = ({ isOpen = false, onClose, onSuccess, data }) => {
  const stateEdit = EditorState.createEmpty();
  const [sourceFile, setSourceFile] = useState(data && !data.redirectUrl ? 'existing' : null);
  const [fileId, setFileId] = useState(data && !data.redirectUrl ? data?.content[0]?.file?.id : null);
  const [notAllowed, setNotAllowed] = useState(false);
  const [fileOptions, setFileOptions] = useState([]);
  const [audioFormat, setFormatAudio] = useState(data?.metadata?.format || null);
  const [paymentType, setPaymentType] = useState(data?.paymentType || 'paid');
  const [hoursDuration, setHoursDuration] = useState(
    data?.metadata?.duration ? secondsToHours(data?.metadata?.duration)?.hours : null
  );
  const [minutesDuration, setMinutesDuration] = useState(
    data?.metadata?.duration ? secondsToHours(data?.metadata?.duration)?.minutes : null
  );

  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);
  const [isDownloadable, setDownloadable] = useState(() => getInitialDownloadableValue(data));

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [enableAffiliate, setEnableAffiliate] = useState(Boolean(data?.affiliateCommission));

  const [insertPaymentLinkBySlug, { loading: insertLoading, data: newpl }] = useMutation(
    GQL_PAYMENTLINK_CREATE_BY_SLUG
  );
  const [editPaymentLinkBySlug, { loading: updateLoading, data: upl }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);
  const [userUserContent, { loading: insertUserContentLoading, data: userContent }] = useMutation(
    GQL_INSERT_USER_CONTENT_FILE
  );
  const [doUpdateUserContent, { loading: updateUserContentLoading, data: updateUserContent }] = useMutation(
    GQL_UPDATE_USER_CONTENT_FILE
  );

  const { data: getUserContent } = useQuery(GQL_GET_CONTENT_FILE, {
    fetchPolicy: 'network-only',
    variables: {
      search: {
        key: [{ operator: 'eq', value: data ? data.id : 'untitle' }]
      }
    }
  });

  const { loading: loadingFiles, data: dataFiles } = useQuery(GQL_GET_USER_CONTENTS, {
    context: { v2: true },
    variables: {
      limit: 100,
      offset: 0
    }
  });

  const { register, handleSubmit, errors, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [date, setDate] = useState(data ? data.expiredAt : null);
  const [link, setLink] = useState(data ? data.link : '');
  const dispatch = useDispatch();
  const isUpdate = !data ? false : true;

  if (!data) data = {};

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    if (!values.limit) {
      delete values.limit;
    } else {
      values.limit = parseInt(values.limit);
    }

    if (values.amount) values.amount = parseFloat(values.amount);

    if (values.crossoutPrice) values.crossoutPrice = parseFloat(values.crossoutPrice);
    else values.crossoutPrice = null;

    if (values.affiliateCommission) values.affiliateCommission = parseInt(values.affiliateCommission);
    else values.affiliateCommission = null;

    let duration;
    if (minutesDuration) {
      const hoursToSeconds = hoursDuration * 3600;
      const minutesToSeconds = minutesDuration * 60;
      duration = hoursToSeconds + minutesToSeconds;
    }

    const metadata = {
      author: values.author,
      format: audioFormat,
      duration
    };

    delete values.author;
    delete values.format;
    delete values.fileSource;
    delete values.hours;
    delete values.minutes;

    if (values.paymentType === 'free') {
      values.amount = 0;
    } else if (values.paymentType === 'voluntary' && !values.amount) values.amount = 0;

    if (date !== null) setDate(date);

    try {
      if (isUpdate) {
        values.id = data.id;

        const editPaymentLink = await editPaymentLinkBySlug({
          variables: {
            input: {
              ...values,
              ...(multipleImageId && { multipleImageId }),
              ...(isRemoveAllProductImage && { multipleImageId: null }),
              metadata,
              expiredAt: date,
              status: 'active',
              description: sanitizeDOM(description),
              isDownloadable: isDownloadable
            }
          }
        });

        // upload file for audiobook
        if (fileId) {
          const contentId =
            getUserContent.getMultipleFileFilteredList.length > 0
              ? getUserContent.getMultipleFileFilteredList[0].id
              : editPaymentLink?.data?.editPaymentLinkBySlug?.id;

          if (getUserContent.getMultipleFileFilteredList.length > 0) {
            await doUpdateUserContent({
              variables: {
                input: [
                  {
                    id: contentId,
                    value: fileId
                  }
                ]
              }
            });
          } else {
            await userUserContent({
              variables: {
                input: [
                  {
                    key: contentId,
                    value: fileId
                  }
                ]
              }
            });
          }
        }

        dispatch(updatePaymentLinks(Math.random()));
      } else {
        if (!fileId) return;

        const paymentLink = await insertPaymentLinkBySlug({
          variables: {
            input: {
              ...values,
              ...(multipleImageId && { multipleImageId }),
              ...(isRemoveAllProductImage && { multipleImageId: null }),
              metadata,
              expiredAt: date,
              link: slug(values.name),
              type: 'audiobook',
              status: 'active',
              description: sanitizeDOM(description),
              isDownloadable: isDownloadable
            }
          }
        });
        if (paymentLink) {
          const paymentLinkId = paymentLink.data.insertPaymentLinkBySlug.id;
          await userUserContent({
            variables: {
              input: [
                {
                  key: paymentLinkId,
                  value: fileId
                }
              ]
            }
          });
        }
        //segment tracking
        window.analytics.track('Create Audiobook', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Audiobook - ERROR', values);
    }
  };

  useEffect(() => {
    if (newpl && newpl.insertPaymentLinkBySlug) onSuccess(newpl.insertPaymentLinkBySlug.id);
  }, [newpl]);

  useEffect(() => {
    if (upl && (userContent || updateUserContent)) onClose();
  }, [upl, userContent, updateUserContent]);

  useEffect(() => {
    if (dataFiles && dataFiles.getUserContents && dataFiles.getUserContents.length && !fileOptions.length) {
      const options = dataFiles.getUserContents.map(i => {
        return { value: i.id, label: i.filename };
      });

      const regex = /\.(?:wav|mp3|flac|ogg|aac)$/i;
      const audioOptions = options.filter(item => (regex.test(item.label) ? item.label : null));
      setFileOptions(audioOptions);
    }
  }, [dataFiles]);

  const handleDownloadable = value => {
    if (value === 'true') {
      setDownloadable(true);
    } else {
      setDownloadable(false);
    }
  };

  return (
    <Modal isOpen={isOpen} fade size="lg">
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Audiobook</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>Penjualan audiobook semakin mudah dengan otomasi download dan halaman produk keren</small>
        </p>
        <hr />
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Nama Produk*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={errors.name}
              defaultValue={data.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="link">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={errors.link}
                defaultValue={data.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="paymentType">Tipe Pembayaran</Label>
            <Controller
              name="paymentType"
              control={control}
              defaultValue={paymentType}
              render={({ onChange, name, value: defaultPaymentType }) => (
                <Select
                  id={name}
                  name={name}
                  options={paymentTypeOptions}
                  defaultValue={paymentTypeOptions.find(obj => obj.value === defaultPaymentType)}
                  placeholder="Pilih Tipe Pembayaran"
                  styles={!!errors.paymentType ? CustomStylesInvalid : CustomStyles}
                  className={`${!!errors.paymentType ? 'is-invalid' : ''}`}
                  onChange={({ value }) => {
                    setPaymentType(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </FormGroup>
          {paymentType === 'paid' && (
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="amount">Harga*</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      invalid={errors.amount}
                      defaultValue={data.amount}
                      innerRef={register({
                        required: validator.required,
                        min: validator.min(1000),
                        max: validator.max(100_000_000)
                      })}
                    />
                    <FormFeedback>{errors.amount?.message}</FormFeedback>
                  </InputGroup>
                  <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="crossoutPrice">Harga Coret (opsional)</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="crossoutPrice"
                      id="crossoutPrice"
                      defaultValue={data?.crossoutPrice}
                      invalid={errors.crossoutPrice}
                      innerRef={register({
                        min: validator.min(1000),
                        max: validator.max(100000000)
                      })}
                    />
                    <FormFeedback>{errors.crossoutPrice?.message}</FormFeedback>
                  </InputGroup>
                  <small>Harga coret harus lebih besar dari harga utama.</small>
                </FormGroup>
              </Col>
            </Row>
          )}
          {paymentType === 'voluntary' && (
            <FormGroup>
              <Label for="amount">Minimal Harga</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  invalid={errors.amount}
                  placeholder="0+"
                  defaultValue={data.amount}
                  innerRef={register({
                    min: validator.min(0),
                    max: validator.max(100_000_000)
                  })}
                />
                <FormFeedback>{errors.amount?.message}</FormFeedback>
              </InputGroup>
              <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>

          <MultipleImageInput
            productName={data.name}
            setMultipleImageId={setMultipleImageId}
            setIsRemoveAllProductImage={setIsRemoveAllProductImage}
            multipleImage={data.multipleImage}
            onLoading={setDisableSubmitButton}
          />

          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={data.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="expiredAt">Tanggal Kadaluarsa</Label>
            <div className="mb-1">
              <DatePicker
                id="expiredAt"
                placeholderText="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date ? new Date(date) : null}
                minDate={new Date()}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
            <small>Kami akan menutup link pembayaran setelah tanggal ini (opsional)</small>
          </FormGroup>
          <FormGroup>
            <Label for="notes">Catatan</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={data.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional).{' '}
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="limit">Maksimum Jumlah Pembayaran (Kuota / QTY)</Label>
            <Input
              type="number"
              name="limit"
              id="limit"
              invalid={errors.limit}
              defaultValue={data.limit}
              innerRef={register()}
            />
            <FormFeedback>{errors?.limit?.message}</FormFeedback>
            <small>
              Kami akan menutup link pembayaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit
              jumlah (unlimited)
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="fileSource">Sumber File*</Label>
            <Input
              id="fileSource"
              name="fileSource"
              type="select"
              onChange={e => {
                setSourceFile(e.target.value);
              }}
              invalid={errors.fileSource}
              innerRef={register({ required: validator.required })}
              defaultValue={sourceFile}
            >
              <option value="">-- Pilih Sumber File --</option>
              {fileOptions.length && <option value="existing">File Lama</option>}
              <option value="new">Upload Baru</option>
            </Input>
            <FormFeedback>{errors?.fileSource?.message}</FormFeedback>
          </FormGroup>

          {/* HIDE JIKA UPLOAD BARU */}
          {sourceFile === 'existing' && (
            <>
              <FormGroup>
                <Label for="downloadFileId">Pilih File Lama*</Label>
                {loadingFiles ? (
                  <span>
                    <LoadingAnimation twodashline />
                  </span>
                ) : (
                  <Select
                    id="downloadFileId"
                    name="downloadFileId"
                    options={fileOptions}
                    styles={CustomStyles}
                    disabled={loadingFiles}
                    isLoading={loadingFiles}
                    defaultValue={
                      data && !data.redirectUrl && data.content
                        ? fileOptions.find(obj => obj.value === data?.content[0]?.file?.id)
                        : null
                    }
                    onChange={e => setFileId(e.value)}
                  />
                )}
                {!fileId && <FormFeedback style={{ display: 'block' }}>File harus diisi</FormFeedback>}
                <small>
                  Pilih file audio dengan format mp3, wav, flac, ogg atau aac. Jika file yang ingin dimasukkan tidak
                  terdapat dalam salah satu format tersebut, silahkan convert format terlebih dahulu.
                </small>
              </FormGroup>
            </>
          )}

          {/* HIDE JIKA PILIH FILE LAMA */}
          {sourceFile === 'new' && (
            <>
              <FormGroup>
                <Label for="audio">File / Konten*</Label>
                <Dropzone
                  setFileId={setFileId}
                  maxFiles={1}
                  multiple={false}
                  setNotAllowed={setNotAllowed}
                  accept="audio/*"
                />
                {!fileId && <FormFeedback style={{ display: 'block' }}>File harus diisi</FormFeedback>}
                {notAllowed && (
                  <FormFeedback style={{ display: 'block' }}>
                    Nama file tidak boleh menggunakan special character!
                  </FormFeedback>
                )}
                <small>
                  Ukuran file maksimal 1GB. Pilih file audio dengan format mp3, wav, flac, ogg atau aac. Jika file yang
                  ingin dimasukkan tidak terdapat dalam salah satu format disamping, silahkan convert format terlebih
                  dahulu.
                </small>
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Label for="isDownloadable">Bisa didownload ?</Label>
            <Input
              id="isDownloadable"
              name="isDownloadable"
              type="select"
              onChange={e => {
                handleDownloadable(e.target.value);
              }}
              defaultValue={isDownloadable === true ? 'true' : 'false'}
            >
              <option value="true">Aktif</option>
              <option value="false">Tidak aktif</option>
            </Input>
          </FormGroup>
          <hr />
          <h4 className="mt-15">Detail Tambahan (opsional)</h4>
          <FormGroup>
            <Label for="author">Narator</Label>
            <Input
              type="text"
              name="author"
              id="author"
              invalid={errors.author}
              defaultValue={data.metadata?.author}
              innerRef={register({ minLength: validator.minLength(5) })}
            />
            <FormFeedback>{errors.author?.message}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="format">Format</Label>
            <Input
              id="format"
              name="format"
              type="select"
              onChange={e => setFormatAudio(e.target.value)}
              defaultValue={audioFormat}
            >
              <option value="">-- Pilih Opsi --</option>
              <option value="WAV">WAV</option>
              <option value="MP3">MP3</option>
              <option value="AAC">AAC</option>
              <option value="FLAC">FLAC</option>
              <option value="OGG">OGG</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="hours">Durasi Audiobook</Label>
            <Input
              className="mb-5"
              type="number"
              placeholder="Jam"
              name="hours"
              id="hours"
              invalid={errors.hours}
              defaultValue={hoursDuration}
              onChange={e => setHoursDuration(e.target.value)}
              innerRef={register({
                min: validator.min(0),
                max: validator.max(24)
              })}
            />
            <Input
              type="number"
              placeholder="Menit"
              name="minutes"
              id="minutes"
              invalid={errors.minutes}
              defaultValue={minutesDuration}
              onChange={e => setMinutesDuration(e.target.value)}
              innerRef={register({
                min: validator.min(1),
                max: validator.max(60)
              })}
            />
            <FormFeedback>{errors.hours?.message || errors.minutes?.message}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <CustomInput
              id="affiliateSwitch"
              label="Produk bisa diaffiliate"
              defaultChecked={enableAffiliate}
              type="switch"
              color="primary"
              onChange={e => setEnableAffiliate(e.target.checked)}
            />
          </FormGroup>

          {enableAffiliate && (
            <FormGroup>
              <Label for="affiliateCommission">Komisi Affiliate</Label>
              <InputGroup>
                <Input
                  type="number"
                  name="affiliateCommission"
                  id="affiliateCommission"
                  defaultValue={data?.affiliateCommission}
                  invalid={errors.affiliateCommission}
                  innerRef={register({
                    min: validator.min(1),
                    max: validator.max(70)
                  })}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>

                <FormFeedback>{errors.affiliateCommission?.message}</FormFeedback>
                <small>
                  Komisi secara otomatis dipotong dari persentase total harga penjualan (setelah dipotong kode diskon
                  jika ada, biaya channel dan biaya Mayar). Komisi hanya untuk affiliator yang terdaftar pada produk ini.
                </small>
              </InputGroup>
            </FormGroup>
          )}

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Update' : 'Buat'} Audiobook`}
            loading
            isDisabled={disableSubmitButton}
            isLoading={insertLoading || updateLoading || insertUserContentLoading || updateUserContentLoading}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyAudiobook;
