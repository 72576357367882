import React, { useEffect, useState } from 'react';
import _, { debounce } from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation, useApolloClient } from '@apollo/client';
import {
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
  InputGroup,
  Modal,
  FormFeedback
} from 'reactstrap';

import { GQL_CUSTOMER_SELECT, GQL_PAYMENTLINK_CREATE_BY_SLUG, GQL_CREATE_INSTALLMENT_INVOICES } from '../../gqls';
import { sanitizeDOM, validator } from '../../utils';
import { addToast } from '../../actions';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import MayarAlert from '../alert/alert';
import Icon from '../icon';
import SubmitButton from '../submit-button';

import RichEditor, { getPlainText, getContentString } from '../rich-text-editor';
import { EditorState } from 'draft-js';

const gparam = {
  limit: 6,
  sortDirection: 'ASC',
  sortField: 'name',
  search: { mobile: [{ operator: 'not', value: '' }, { operator: 'notEmpty' }] }
};

const installmentPeriodOptions = [
  { value: 'WEEKLY', label: 'Mingguan', isDisabled: true },
  { value: 'MONTHLY', label: 'Bulanan' },
  { value: 'CUSTOM', label: 'Custom', isDisabled: true }
];

const ModalInstallment = ({ isOpen, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { register, handleSubmit, errors, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [periodLabel, setPeriodLabel] = useState(installmentPeriodOptions[1].label.replace(/an/, ''));
  const [dueDateType, setDueDateType] = useState('FIRST_PAYMENT');

  const stateEdit = EditorState.createEmpty();
  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [invalidDescription, setInvalidDescription] = useState(false);

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length > 0 && textDescription.length < 5) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  const [insertPaymentLink, { loading }] = useMutation(GQL_PAYMENTLINK_CREATE_BY_SLUG);
  const [createInstallmentInvoices, { loading: loadingInvoices }] = useMutation(GQL_CREATE_INSTALLMENT_INVOICES);

  const dueDateTypeOptions = [
    { value: 'FIRST_PAYMENT', label: 'Sesuai Tanggal Pembuatan Cicilan' },
    { value: 'CUSTOM_DATE', label: `Tanggal Tetap Setiap ${periodLabel}` }
  ];

  const dueDateOptions = Array.from(Array(28).keys()).map(i => ({
    value: i + 1,
    label: `Tanggal ${i + 1} Setiap ${periodLabel}`
  }));

  const mapOptionsToValues = options =>
    options.map(option => ({
      value: option.id,
      label: option.name + ' (' + option.email + ')'
    }));

  const _loadSuggestions = (keyword, callback) => {
    if (!keyword || keyword.length < 3) {
      return callback([]);
    }

    gparam.searchAny = {
      email: [{ operator: 'like', value: keyword + '%' }],
      name: [{ operator: 'like', value: '%' + keyword + '%' }]
    };
    client
      .query({
        query: GQL_CUSTOMER_SELECT,
        variables: gparam,
        context: { queryDeduplication: false }
      })
      .then(resp => callback(mapOptionsToValues(resp.data.getCustomerFilteredList)));
  };
  const loadSuggestions = debounce(_loadSuggestions, 500);

  const sendMail = async (paymentLinkId, customerEmail) => {
    try {
      await axios.post(`${process.env.REACT_APP_PROXY}/mail-new-invoice`, { id: paymentLinkId });
      dispatch(
        addToast({
          title: 'Email terkirim',
          content: 'Kami telah mengirimkan email penagihan ini kepada ' + customerEmail,
          duration: 3000
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: 'Email gagal dikirim',
          duration: 3000,
          color: 'danger'
        })
      );
    }
  };

  const onSubmit = async values => {
    if (textDescription.length > 0 && textDescription.length < 5) {
      setInvalidDescription(true);
      return;
    }
    try {
      const resPL = await insertPaymentLink({
        variables: {
          input: {
            name: values.name,
            amount: parseFloat(values.amount),
            // description: values.description,
            description: sanitizeDOM(description),
            customerId: values.customerId.value,
            type: 'installment',
            status: 'unpaid',
            installment: {
              interest: parseFloat(values.interest),
              interestType: 'FLAT',
              tenure: parseInt(values.tenure),
              period: values.period?.value,
              dueDate: values.dueDate?.value || new Date().getDate()
            }
          }
        }
      });
      const paymentLink = _.get(resPL, 'data.insertPaymentLinkBySlug', {});
      const resInvoices = await createInstallmentInvoices({
        variables: { id: paymentLink?.installment?.id }
      });
      const installmentInvoices = _.get(resInvoices, 'data.createInstallmentInvoices', {});

      if (installmentInvoices?.status !== 'SUCCESS') throw new Error('Failed to create installment invoices');

      if (values.sendNotif && installmentInvoices?.invoices?.length) {
        sendMail(
          installmentInvoices?.invoices[0]?.id,
          values?.customerId?.label || installmentInvoices?.invoices[0]?.customer?.email
        );
      }

      onSuccess(paymentLink?.id);
      //segment tracking
      window.analytics.track('Create Payment Request', values);
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Payment Request - ERROR', values);
    }
  };

  return (
    <Modal isOpen={isOpen} fade size="lg">
      <div className="modal-header">
        <h5 className="modal-title h2">Buat Cicilan</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Buat cicilan dengan penagihan otomatis dengan mudah. Atur besaran tanggungan, interest dan tenor dengan
            mudah.
          </small>
        </p>
        <hr />
        <MayarAlert isError={submitError} className="mb-10" />
        <Form onSubmit={handleSubmit(onSubmit)} id="create-installment">
          <FormGroup>
            <Label for="customerId">Pelanggan*</Label>
            <Controller
              name="customerId"
              control={control}
              defaultValue=""
              rules={{ required: validator.required }}
              as={
                <AsyncSelect
                  inputId="customerId"
                  className={Boolean(errors.customerId) ? 'is-invalid' : ''}
                  loadOptions={loadSuggestions}
                  defaultOptions
                  escapeClearsValue={true}
                  openMenuOnClick={false}
                  isSearchable
                  isClearable={true}
                  placeholder="Cari pelanggan...(ketik minimal 3 huruf awal)"
                  cacheOptions={false}
                  styles={Boolean(errors.customerId) ? CustomStylesInvalid : CustomStyles}
                />
              }
            />
            <FormFeedback>{errors.customerId?.message}</FormFeedback>
            <small>
              Kamu perlu membuat data pelanggan dahulu di <Link to="/customers">Halaman Pelanggan</Link>
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="amount">Jumlah*</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Rp</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                name="amount"
                id="amount"
                invalid={Boolean(errors.amount)}
                innerRef={register({
                  required: validator.required,
                  min: validator.min(1000),
                  max: validator.max(1_000_000_000)
                })}
              />
              <FormFeedback>{errors.amount?.message}</FormFeedback>
            </InputGroup>
            <small>Total pokok yang harus dibayar</small>
          </FormGroup>
          <FormGroup>
            <Label for="interest">Suku Bunga (Per-tahun)*</Label>
            <InputGroup>
              <Input
                type="number"
                name="interest"
                id="interest"
                invalid={Boolean(errors.interest)}
                placeholder="0"
                defaultValue={0}
                innerRef={register({
                  required: validator.required,
                  min: validator.min(0),
                  max: validator.max(1000)
                })}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
              <FormFeedback>{errors.interest?.message}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="period">Periode Cicilan*</Label>
            <Controller
              name="period"
              control={control}
              defaultValue={installmentPeriodOptions[1]}
              rules={{ required: validator.required }}
              render={({ onChange, value }) => (
                <Select
                  inputId="period"
                  value={value}
                  options={installmentPeriodOptions}
                  styles={Boolean(errors.period) ? CustomStylesInvalid : CustomStyles}
                  className={Boolean(errors.period) ? 'is-invalid' : ''}
                  placeholder="Pilih periode cicilan..."
                  onChange={e => {
                    onChange(e);
                    setPeriodLabel(e?.label?.replace(/an/, ''));
                  }}
                />
              )}
            />
            <FormFeedback>{errors.period?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="tenure">Lama Cicilan*</Label>
            <InputGroup>
              <Input
                type="number"
                name="tenure"
                id="tenure"
                invalid={Boolean(errors.tenure)}
                innerRef={register({
                  required: validator.required,
                  min: validator.min(1),
                  max: validator.max(1000)
                })}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{periodLabel}</InputGroupText>{' '}
              </InputGroupAddon>
              <FormFeedback>{errors.tenure?.message}</FormFeedback>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="dueDateType">Tanggal Pembayaran*</Label>
            <Controller
              name="dueDateType"
              control={control}
              defaultValue={dueDateTypeOptions[0]}
              rules={{ required: validator.required }}
              render={({ onChange, value }) => (
                <Select
                  inputId="dueDateType"
                  value={value}
                  options={dueDateTypeOptions}
                  styles={Boolean(errors.dueDateType) ? CustomStylesInvalid : CustomStyles}
                  className={Boolean(errors.dueDateType) ? 'is-invalid' : ''}
                  placeholder="Pilih tanggal pembayaran..."
                  onChange={e => {
                    onChange(e);
                    setDueDateType(e?.value);
                  }}
                />
              )}
            />
            <FormFeedback>{errors.dueDateType?.message}</FormFeedback>
          </FormGroup>
          {dueDateType === 'CUSTOM_DATE' && (
            <FormGroup>
              <Label for="dueDate">Pilih Tanggal Pembayaran Tiap {periodLabel}*</Label>
              <Controller
                name="dueDate"
                control={control}
                defaultValue=""
                rules={{ required: validator.required }}
                render={({ onChange, value }) => (
                  <Select
                    inputId="dueDate"
                    value={value}
                    options={dueDateOptions}
                    styles={Boolean(errors.dueDate) ? CustomStylesInvalid : CustomStyles}
                    className={Boolean(errors.dueDate) ? 'is-invalid' : ''}
                    placeholder="Pilih tanggal pembayaran..."
                    onChange={onChange}
                  />
                )}
              />
              <FormFeedback>{errors.dueDate?.message}</FormFeedback>
            </FormGroup>
          )}

          <hr />

          <FormGroup>
            <Label for="name">Nama Cicilan*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={Boolean(errors.name)}
              innerRef={register({ required: validator.required, minLength: validator.minLength(3) })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          {/* <FormGroup>
            <Label for="description">Deskripsi</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              invalid={Boolean(errors.description)}
              innerRef={register({ minLength: validator.minLength(5) })}
            />
            <FormFeedback>{errors.description?.message}</FormFeedback>
          </FormGroup> */}
          <FormGroup>
            <Label for="description">Deskripsi</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>
              Harus berisi minimal 5 karakter
            </FormFeedback>
          </FormGroup>
          <hr />
          <FormGroup>
            <CustomInput
              id="sendNotif"
              name="sendNotif"
              label="Kirim Tagihan Pembayaran Pertama"
              type="switch"
              color="primary"
              innerRef={register()}
            />
          </FormGroup>
          <SubmitButton
            size="lg"
            block
            color="brand"
            className="text-center"
            type="submit"
            loading
            isLoading={loading || loadingInvoices}
            text="Buat Cicilan"
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

ModalInstallment.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ModalInstallment;
